import React, { useEffect } from "react";
import { connect } from "react-redux"
import { connectMetamask } from "../../blockchain/connect";
import { addAddress, addCBContract, addGBContract,addGMContract , addMainContract, addRPTContract, addUSDTContract, connectCBContract, connectGBContract,connectGMContract, connectMainContract, connectRPTContract, connectUSDTContract } from "../../redux/actions/BlockchainActions";

const Wallet = (props) => {

  const connectWeb3 = async () => { 

    await connectMetamask(
      props.funaddAddress,
      props.funaddUSDTContract,
      props.funaddGBContract,
      props.funaddCBContract,
      props.funaddGMContract,
      props.funaddRPContract,
      props.funaddMainContract,
      props.funconnectUSDTContract,
      props.funconnectGBContract,
      props.funconnectCBContract,
      props.funconnectGMContract,
      props.funconnectRPContract,
      props.funconnectMainContract,
    )

  }

  useEffect(() => {
    console.log(props.blockchainData)
  }, [props])
  
  

return (
  <div className="row">
    <div className="col-lg-3 mb30" onClick={() => connectWeb3()}>
      <span className="box-url">
        <span className="box-url-label">Most Popular</span>
        <img src="./img/wallet/1.png" alt="" className="mb20" />
        <h4>Victoria</h4>
        <p>
          Ahmad Software and technology give you solution for every problem{" "}
        </p>
      </span>
    </div>

    <div className="col-lg-3 mb30">
      <span className="box-url">
        <img src="./img/wallet/2.png" alt="" className="mb20" />
        <h4>Victoria</h4>
        <p>
          Ahmad Software and technology give you solution for every problem{" "}
        </p>
      </span>
    </div>

    <div className="col-lg-3 mb30">
      <span className="box-url">
        <img src="./img/wallet/3.png" alt="" className="mb20" />
        <h4>Victoria</h4>
        <p>
          Ahmad Software and technology give you solution for every problem{" "}
        </p>
      </span>
    </div>

    <div className="col-lg-3 mb30">
      <span className="box-url">
        <img src="./img/wallet/4.png" alt="" className="mb20" />
        <h4>Victoria</h4>
        <p>
          Ahmad Software and technology give you solution for every problem{" "}
        </p>
      </span>
    </div>

    <div className="col-lg-3 mb30">
      <span className="box-url">
        <img src="./img/wallet/5.png" alt="" className="mb20" />
        <h4> Victoria</h4>
        <p>
          Ahmad Software and technology give you solution for every problem{" "}
        </p>
      </span>
    </div>

    <div className="col-lg-3 mb30">
      <span className="box-url">
        <img src="./img/wallet/6.png" alt="" className="mb20" />
        <h4>Victoria</h4>
        <p>
          Ahmad Software and technology give you solution for every problem{" "}
        </p>
      </span>
    </div>

    <div className="col-lg-3 mb30">
      <span className="box-url">
        <img src="./img/wallet/7.png" alt="" className="mb20" />
        <h4>Victoria</h4>
        <p>
          Ahmad Software and technology give you solution for every problem{" "}
        </p>
      </span>
    </div>

    <div className="col-lg-3 mb30">
      <span className="box-url">
        <span className="box-url-label">Most Simple</span>
        <img src="./img/wallet/8.png" alt="" className="mb20" />
        <h4>Torus</h4>
        <p>
          Ahmad Software and technology give you solution for every problem{" "}
        </p>
      </span>
    </div>
  </div>
)

}

const mapStateToProps = state => ({
  blockchainData: state
})

const mapDispatchToProps = dispatch => ({
  funaddAddress: data => dispatch(addAddress(data)),
  funaddUSDTContract: data => dispatch(addUSDTContract(data)),
  funaddGBContract: data => dispatch(addGBContract(data)),
  funaddCBContract: data => dispatch(addCBContract(data)),
  funaddGMContract: data => dispatch(addGMContract(data)),
  funaddRPContract: data => dispatch(addRPTContract(data)),
  funaddMainContract: data => dispatch(addMainContract(data)),
  funconnectUSDTContract: data => dispatch(connectUSDTContract(data)),
  funconnectGBContract: data => dispatch(connectGBContract(data)),
  funconnectCBContract: data => dispatch(connectCBContract(data)),
  funconnectGMContract: data => dispatch(connectGMContract(data)),
  funconnectRPContract: data => dispatch(connectRPTContract(data)),
  funconnectMainContract: data => dispatch(connectMainContract(data)),
})

export default connect(mapStateToProps,mapDispatchToProps) (Wallet);
