import { ethers, providers } from "ethers";
import Web3Modal from "web3modal"

import USDTToken from "./abis/USDTToken";
import GBToken from "./abis/GBToken.json";
import GMToken from "./abis/GMToken.json";
import CBToken from "./abis/CBToken.json";
import RPToken from "./abis/RPToken.json";
import VictoriaMain from "./abis/VictoriaMain.json";

export const connectMetamask = async (
  addAddress,
  addUSDTContract,
  addGBContract,
  addCBContract,
  addGMContract,
  addRPContract,
  addMainContract,
  connectUSDTContract,
  connectGBContract,
  connectCBContract,
  connectGMContract,
  connectRPContract,
  connectMainContract
) => {

  if (window.ethereum) {


        const web3modal = new Web3Modal()
        const provider = await web3modal.connect()
        const web3Provider = new providers.Web3Provider(provider)
    
        const signer = web3Provider.getSigner()
        const address = await signer.getAddress()
        addAddress(address);
        console.log(address)
        //localStorage.setItem('userAddress',address)
    
      

   // const provider = new ethers.providers.Web3Provider(window.ethereum);
   // console.log(getProviderOrSigner(true))
   // const signer = await provider.getSigner();
//
   // const address = await signer.getAddress();
   // console.log(signer.getAddress());
//
   // console.log(address);

    const usdt = new ethers.Contract(
      USDTToken.address,
      USDTToken.abi,
      web3Provider
    );
    const usdtConnected = usdt.connect(signer);

    console.log(usdt)
    console.log(usdtConnected)

    const gb = new ethers.Contract(GBToken.address, GBToken.abi, web3Provider);
    const gbConnected = gb.connect(signer);

    const cb = new ethers.Contract(CBToken.address, CBToken.abi, web3Provider);
    const cbConnected = cb.connect(signer);

    const gm = new ethers.Contract(GMToken.address, GMToken.abi, web3Provider);
    const gmConnected = gm.connect(signer);

    const rp = new ethers.Contract(RPToken.address, RPToken.abi, web3Provider);
    const rpConnected = rp.connect(signer);

    const main = new ethers.Contract(
      VictoriaMain.address,
      VictoriaMain.abi,
      web3Provider
    );
    const mainConnected = main.connect(signer);

    mainConnected.myUSDTBalance()
    .then((response => {
      console.log(response)
    }))
    .catch(error => console.log(error))

    addUSDTContract(usdt);
    addGBContract(gb);
    addCBContract(cb);
    addGMContract(gm);
    addRPContract(rp);
    addMainContract(main);
    connectUSDTContract(usdtConnected);
    connectGBContract(gbConnected);
    connectCBContract(cbConnected);
    connectGMContract(gmConnected);
    connectRPContract(rpConnected);
    connectMainContract(mainConnected);
  } else {
    alert("install any crypro waller");
  }
};
