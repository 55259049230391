import React from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { Card, Col, Form, InputGroup } from "react-bootstrap";
import FormContainer from "./FormContainer";

const task_list = (props) => (
  <div>
    <FormContainer>
      <section className="container mt-5 task-container ">
        <div className="row">
          <div className="spacer-double"></div>
          <div className="col-md-8 offset-md-2 text ">
            {/* <h3>Don't have an account? Register now.</h3> */}
            <h2 className="mb-5">Task List</h2>
            <div className="row text-center ">
              <Col className="col-12"></Col>
            </div>

            <form
              name="contactForm"
              id="contact_form"
              className="form-border justify-content-center"
              action="#"
            >
              <div
                className="row "
                // style={{ marginBottom: "2rem", marginTop: "4rem" }}
              >
                <div className="col-md-12 d-block">
                  <div className="field-set">
                    <div className="justify-content-md-center "></div>
                    <InputGroup>
                      <Col className="col-12">
                        <Card>
                          <Card.Body>
                            <div className="d-flex justify-content-spaced-between">
                              <span className="ms-2 me-2">Stake Date:</span>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </InputGroup>
                    <InputGroup className="mt-2 mb-2">
                      <Col className="col-12">
                        <Card>
                          <Card.Body>
                            <div className="d-flex justify-content-spaced-between">
                              <span className="ms-2 me-1">Expired Date:</span>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </InputGroup>
                    <InputGroup className="mt-2 mb-2">
                      <Col className="col-12">
                        <Card>
                          <Card.Body>
                            <div className="d-flex justify-content-spaced-between">
                              <span className="ms-2 me-2">Stake Amount:</span>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </InputGroup>
                    <InputGroup className="mt-2 mb-2">
                      <Col className="col-12">
                        <Card>
                          <Card.Body>
                            <div className="d-flex justify-content-spaced-between">
                              <span className="ms-2 me-2">Stake Status:</span>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </InputGroup>
                    <InputGroup className="mt-2 mb-2">
                      <Col className="col-12">
                        <Card>
                          <Card.Body>
                            <div className="d-flex justify-content-spaced-between">
                              <span className="ms-2 me-2">Airdrop CH:</span>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="row  ">
                <div className="col-md-12 text-center ">
                  <div className="">
                    <input
                      type="submit"
                      id="send_message"
                      value="Radeem"
                      className="btn btn-main color-2"
                      style={{
                        fontSize: "20px",
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </FormContainer>
    {/* <Footer /> */}
  </div>
);
export default task_list;
