import React, { useMemo, useState } from "react";
import SliderCarousel from "../components/SliderCarousel";
import FeatureBox from "../components/FeatureBox";
import CarouselCollection from "../components/CarouselCollection";
import ColumnNew from "../components/ColumnNew";
import Accordion from "./accordion";
import AuthorList from "../components/authorList";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import Register from "./register";
import Home3 from "./home3";
import { useEffect } from "react";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #212428;
    border-bottom: 0;
    box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
`;

const homethree = () => {
  return (
    <div className="section-home-1">
      <GlobalStyles />
      <section className="jumbotron no-bg no-bottom"></section>
      <section className="container section-home-1 pt-5 mt-5">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="mb-5">Expansion Stake To Earn</h2>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 hero-col ">
            <ColumnNew stake="stake" width="100%" />
          </div>
          <div className="col-lg-6">
            <ColumnNew lock="lock" width="100%" />
          </div>
        </div>
      </section>
      <section className="container no-top" style={{ marginBottom: "0px" }}>
        <div className="container no-top">
          <div className="row">
            <div className="col-lg-12 px-0">
              {/* <CarouselCollection />
               */}
              <Accordion />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default homethree;
