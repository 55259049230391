import React, { Component } from "react";
import styled from "styled-components";


const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
`;

export default class Responsive extends Component {
  dummyData = [
    {
      deadline: "December, 30, 2023",
      authorLink: "ItemDetail",
      nftLink: "ItemDetail",
      bidLink: "ItemDetail",
      authorImg: "./img/author/author-1.jpg",
      previewImg: "./img/items/static-1.jpg",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-10.jpg",
      previewImg: "./img/items/static-2.jpg",
      title: "Victoria",
      price: "0.06 ETH",
      bid: "1/22",
      likes: 80,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-11.jpg",
      previewImg: "./img/items/static-3.jpg",
      title: "Victoria",
      price: "0.05 ETH",
      bid: "1/11",
      likes: 97,
    },
    {
      deadline: "January, 1, 2023",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-12.jpg",
      previewImg: "./img/items/static-4.jpg",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-9.jpg",
      previewImg: "./img/items/anim-4.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      nfts: this.dummyData,
      height: 0,
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  onImgLoad({ target: img }) {
    let currentHeight = this.state.height;
    if (currentHeight < img.offsetHeight) {
      this.setState({
        height: img.offsetHeight,
      });
    }
  }

  render() {
    return (
      <div className="row">
        {this.state.nfts.map((nft, index) => (
          <div
            key={index}
            className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12"
          >
            <div className="nft__item">
              <div
                className="nft__item_wrap"
                style={{ height: `${this.state.height}px` }}
              >
                <Outer>
                  <span>
                    <img
                      onLoad={this.onImgLoad}
                      src={nft.previewImg}
                      className="lazy nft__item_preview img-fluid"
                      alt=""
                    />
                  </span>
                </Outer>
              </div>
              <div className="box-mim text-center pt-3 pb-2 mt-2">
                <span onClick={() => window.open(nft.nftLink, "_self")}>
                  <h4>{nft.title}</h4>
                </span>
                <div className="nft__item_price">
                  {nft.price}
                  <span>{nft.bid}</span>
                </div>
              </div>
              <div className="  d-flex justify-content-between mt-2">
                <div className="col-6 box-mim-down text-center pt-1 pb-2">
                  <i
                    className="fa fa-credit-card"
                    aria-hidden="true"
                    style={{ color: "#fff" }}
                  ></i>{" "}
                  <span className="sold-text">SOLD</span>
                  <div className="d-flex justify-content-center">
                    <div className="mini-box pt-1 pb-1">15</div>
                  </div>
                </div>
                <div className="col-6 box-mim-down text-center pt-1 pb-2">
                  <i
                    class="fa fa-clock-o"
                    aria-hidden="true"
                    style={{ color: "#fff" }}
                  ></i>{" "}
                  <span className="sold-text">TIME</span>
                  <div className="d-flex justify-content-center">
                    <div className="mini-box pt-1 pb-1">12D: 13H</div>
                  </div>
                </div>
              </div>
              <div className="d-flex  justify-content-between mt-2">
                <div className="minus-box">
                  <i
                    class="fa fa-minus"
                    aria-hidden="true"
                    style={{ color: "#fff" }}
                  ></i>
                </div>
                <div className="target-box text-center">
                  <i
                    class="fa fa-ticket"
                    aria-hidden="true"
                    style={{ color: "#fff" }}
                  ></i>{" "}
                  &nbsp; Tickets
                </div>
                <div className="minus-box">
                  <i
                    class="fa fa-plus"
                    aria-hidden="true"
                    style={{ color: "#fff" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
