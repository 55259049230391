import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;
const dummyData =
  "Ahmad Software and technology give you solution for every problem";
const styleall = {
  textDecoration: "none",
};
const accordion = () => (
  <div style={{ marginBottom: "0px" }}>
    <GlobalStyles />

    <div className="container">
      <div className="row">
        <div className="col-12">
          <h3 className="text-center">Frequently Asked Question</h3>
        </div>
      </div>
    </div>
    <hr />

    <Row className="w-100 mt-2 ms-1 ">
      <Col className=" me-0 ">
        {/* <h3>Default</h3> */}
        <Accordion>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <p className="faq-head">The Emergence of Victoria?</p>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <p className="faq-para">
                  Over the years, Victoria has served as a private venture
                  capital institution, providing funding to numerous mining
                  conglomerates to support their mining operations.
                  <br />
                  In recent times, global economic impacts caused by factors
                  such as the pandemic, tense international relations,
                  geopolitical conflicts, and more, have led to economic
                  turmoil, triggering a surge in demand for safe-haven assets
                  like gold. Victoria seized this opportunity by acquiring a
                  significant amount of gold and actively holding onto it.
                  <br />
                  
                  Simultaneously, Victoria recognizes the promising future of
                  blockchain in the financial sector, believing that robust
                  community organizations are essential for the healthy
                  development of the blockchain ecosystem. To expand the
                  community and achieve sustainable growth, we have introduced a
                  series of initiatives: - Issuing 1 billion GB Tokens to
                  incentivize community members participating in the staking
                  program. - Issuing 1 billion CH Tokens to reward members
                  actively engaged in community maintenance.
                  <br />
                  Allocating 3 million grams of gold as rewards for CH token
                  holders, expediting the establishment of a secure gold trading
                  and payment environment, benefiting professionals in the
                  field. These measures will propel the growth of the Victoria
                  community, fostering innovative development in the financial
                  and gold sectors, and creating more opportunities and value
                  for the future.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                <p className="faq-head">
                  The Staking Plans Introduced by Victoria Offer Multiple
                  Advantages?
                </p>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                {" "}
                <p className="faq-para">
                  Flexible Staking: Participants can earn a daily return of
                  0.2%, and have the flexibility to redeem their staked capital
                  at any time. This model provides great financial flexibility,
                  allowing you to withdraw funds according to your personal
                  needs while continuously earning returns.
                  <br />
                  90-Day Locked Staking: Participants can earn a daily return of
                  0.4%, and the staked capital must remain locked for 90 days
                  before redemption. Additionally, locked staking grants you
                  eligibility to participate in community-building activities
                  and offers an opportunity to share in Victoria's profits.
                  <br />
                  These staking plans are versatile and cater to the needs of
                  different investors. You can choose the current staking for
                  flexibility in accordance with your financial situation and
                  risk tolerance, or opt for locked staking to earn higher
                  returns. Regardless of your choice, locked staking will also
                  provide you with more opportunities for community engagement
                  and profit sharing.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                <p className="faq-head">
                  Victoria Community Building Initiative?
                </p>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                {" "}
                <p className="faq-para">
                  The Victoria community-building plan aims to encourage more
                  individuals to participate in community development and
                  maintenance. Through this program, participants can earn CH
                  Tokens. The plan offers three methods of computing power
                  release to acquire CH Tokens, as outlined below:
                  <br />
                  1. Direct Invitation Computing Power:
                  <ul>
                    <li>First Generation: 18%</li>
                    <li>Second Generation: 3% </li>
                    <li>Third Generation: 3%</li>
                  </ul>
                  Calculation: The daily released GB Token amount of members
                  directly invited by you is multiplied by the corresponding
                  generation's percentage.
                  <br />
                  Conditions: Referring one person entitles you to the first
                  generation, referring two people entitles you to the second
                  generation, and referring three people entitles you to the
                  third generation.
                  <br />
                  Community Organization Level Computing Power:
                  <ul>
                    <li> - V1 Level: 9% </li>
                    <li>- V2 Level: 18%</li>
                    <li>- V3 Level: 27% </li>
                    <li> - V4 Level: 36% </li>
                    <li> - V5 Level: 45% </li>
                    <li> - V6 Level: 54%</li>
                  </ul>
                  Calculation: Based on your organization level, the daily
                  released GB Token amount of members in your organization is
                  multiplied by the corresponding level's percentage. Upgrade
                  conditions also gradually increase with the number of members
                  and levels.
                  <br />- Upgrade Conditions:
                  <ul>
                    <li>- Upgrade to V1 with a total pledge of 5000 USDT.</li>
                    <li>- Upgrade to V2 with 3 members at V1.</li>
                    <li> - Upgrade to V3 with 3 members at V2.</li>
                    <li> - Upgrade to V3 with 3 members at V2.</li>
                    <li> - Upgrade to V4 with 3 members at V3.</li>
                    <li> - Upgrade to V5 with 3 members at V4.</li>
                    <li>- Upgrade to V6 with 3 members at V5. </li>
                  </ul>{" "}
                  3. Community Organization Same-Level Computing Power:
                  <br />
                  <ul>
                    <li>V6 to V6: 15% </li>
                  </ul>
                  In the scenario where you have directly invited members in the
                  same V6 level as you, you will receive a percentage of the
                  total daily earnings of your directly invited V6 members,
                  multiplied by the same-level community organization computing
                  power percentage. These methods of computing power release
                  provide participants with various ways to earn CH Tokens,
                  encouraging active participation in community construction and
                  operation.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                <p className="faq-head">Earning Profit Shares from Victoria?</p>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                {" "}
                <p className="faq-para">
                  In the plan to earn profit shares from Victoria, all you need
                  to do is stake your CH Tokens, and you'll receive 5 GM Tokens
                  daily. Upon accumulating 1000 GM Tokens, you can exchange them
                  for 1 PR Token. With 1 PR Token in your possession, you have
                  the opportunity to visit the Victoria Community Center and
                  convert it into 1 gram of 999 purity gold. This plan provides
                  you with a stable opportunity for asset growth, enabling you
                  to effortlessly participate in Victoria's profit-sharing
                  initiative.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="4">
                <p className="faq-head">Building the Victoria Ecosystem?</p>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                {" "}
                <p className="faq-para">
                  1. GB Token Issuance: We have minted 1 billion GB Tokens with
                  the aim of rapidly fostering a sizable community member base,
                  laying the foundation for Victoria's prosperity.
                  <br />
                  2. CH Token Issuance: We've created 1 billion CH Tokens to
                  support the continual development of community culture,
                  providing community members with a diverse and vibrant
                  ecosystem.
                  <br />
                  3.GM Token Issuance: We've minted 3 billion GM Tokens, which
                  equate to the value of rewarding community maintainers with 3
                  tons of gold. This will incentivize active participation and
                  healthy growth within the community, ensuring the stability
                  and prosperity of the Victoria ecosystem. Within the Victoria
                  ecosystem, these anticipated token issuances will drive the
                  flourishing development of the community, offering abundant
                  opportunities and value to each member.
                </p>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
    </Row>
  </div>
);
export default accordion;
