import React, { useState } from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { Button, Card, Col, Form } from "react-bootstrap";
import FormContainer from "./FormContainer";
import { connect } from "react-redux";

const account = (props) => {
  const token = {
    GB: 0,
    CB: 0,
    GM: 0,
    RP: 0,
    Rank: 0,
  };

  const dataGetter = () => {
    props.blockchainData.blockchain.gbcontract
      .myBalance()
      .then((response) => {
        token.GB = response * 1e18;
      })
      .catch((error) => console.log(error));

    props.blockchainData.blockchain.cbcontract
      .myBalance()
      .then((response) => {
        token.CB = response * 1e18;
      })
      .catch((error) => console.log(error));

    props.blockchainData.blockchain.gmcontract
      .myBalance()
      .then((response) => {
        token.GM = response * 1e18;
      })
      .catch((error) => console.log(error));

    props.blockchainData.blockchain.rpcontract
      .myBalance()
      .then((response) => {
        token.RP = response * 1e18;
      })
      .catch((error) => console.log(error));

    props.blockchainData.blockchain.maincontract
      .myRanking()
      .then((response) => {
        token.Rank = response;
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="mb-5 pb-5 register-main account-container">
      <FormContainer>
        <section className="container ">
          <div className="row justify-content-center">
            <div className="spacer-double"></div>
            <div className="col-md-12   text-center">
              <Col>
                <Col className="col-12">
                  <h2 className=" "> Account</h2>
                  <div className="spacer-double"></div>
                  <h6
                    className="text-center "
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Invite your friends and get more $CB
                  </h6>
                  <h5
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Send the link to your friend:
                  </h5>
                  <h6
                    style={{
                      textDecoration: "underline",
                      fontSize: "15px",
                    }}
                  >
                    http://xxx.vercel.app
                  </h6>
                  <h5
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Send your ID to friend :
                  </h5>
                  <h6
                    style={{
                      textDecoration: "underline",
                      fontSize: "15px",
                    }}
                  >
                    0xbxxxxxxxxxxxxx
                  </h6>
                </Col>

                <form
                  name="contactForm"
                  id="contact_form"
                  className="form-border justify-content-center"
                  action="#"
                >
                  <div
                    className="row text-center align-item-center justify-content-center"
                    style={{ marginBottom: "2rem", marginTop: "4rem" }}
                  >
                    {/* <Col className=" d-flex justify-content-center align-items-center">
                  <Card className="p-3" style={{ width: "22rem" }}>
                    <Card.Body>
                      <Card.Title>
                        <strong>CH</strong>
                      </Card.Title>
                      <Card.Title>
                        <strong>Balance</strong>
                      </Card.Title>
                    </Card.Body>
                    <h3 style={{ fontSize: "37px" }}>0</h3>
                  </Card>
                </Col> */}
                    {/* <Col
                  className=" d-flex justify-content-center align-items-center"
                  style={{
                    margin: "auto",
                    width: "50%",
                  }}
                >
                  <Card className="p-3" style={{ width: "22rem" }}>
                    <Card.Body>
                      <Card.Title>
                        <strong>MP</strong>
                      </Card.Title>
                      <Card.Title>
                        <strong>Reserve</strong>
                      </Card.Title>
                    </Card.Body>
                    <h3 style={{ fontSize: "40px" }}>0</h3>
                  </Card>
                </Col> */}
                  </div>
                  <Col
                    className="border p-5 text-center"
                    style={{
                      borderRadius: "20px",
                    }}
                  >
                    <div
                      className="row "
                      style={{ marginBottom: "2rem", marginTop: "4rem" }}
                    >
                      <Col>
                        <div className="row text-center">
                          <Col className="  d-flex justify-content-center align-items-center">
                            <Card
                              className="p-auto text-center"
                              style={{
                                width: "100%",
                                backgroundColor: "#e58a06",
                                borderRadius: "20px",
                              }}
                            >
                              <Card.Body className="text-center">
                                <Card.Title className=" ">
                                  <strong as="h3" className="text-center">
                                    RANK
                                  </strong>

                                  <strong>V{token.Rank}</strong>
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                        </div>
                        <div className="row mt-4 text-center d-flex justify-content-center align-items-center ">
                          <Col
                            className="col-5 d-flex justify-content-right align-items-right  border p-3"
                            md={12}
                            xs={12}
                            sm={12}
                            lg={5}
                            xl={5}
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <Card className="" style={{ width: "100%" }}>
                              <Card.Body>
                                <Card.Title>
                                  <div className="row text-center">
                                    <Col className="  d-flex justify-content-center align-items-center mb-3">
                                      <Card
                                        className="p-auto text-center"
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#e58a06",
                                          borderRadius: "20px",
                                        }}
                                      >
                                        <Card.Body className="text-center">
                                          <Card.Title className=" ">
                                            <strong
                                              as="h3"
                                              className="text-center"
                                            >
                                              Total User
                                            </strong>
                                          </Card.Title>
                                        </Card.Body>
                                      </Card>
                                    </Col>
                                  </div>
                                  <h4 style={{ fontSize: "40px" }}>
                                    {token.GB}
                                  </h4>
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                          <div className="col-1"></div>
                          <Col
                            className="col-5 d-flex justify-content-end align-items-center border p-3 mt-2"
                            md={12}
                            xs={12}
                            sm={12}
                            lg={5}
                            xl={5}
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <Card className="" style={{ width: "100%" }}>
                              <Card.Body>
                                <div className="row text-center">
                                  <Col className="  d-flex justify-content-center align-items-center mb-3">
                                    <Card
                                      className="p-auto text-center"
                                      style={{
                                        width: "100%",
                                        backgroundColor: "#e58a06",
                                        borderRadius: "20px",
                                      }}
                                    >
                                      <Card.Body className="text-center">
                                        <Card.Title className=" ">
                                          <strong
                                            as="h3"
                                            className="text-center"
                                          >
                                            Total Staking
                                          </strong>
                                        </Card.Title>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </div>
                                <Card.Title>
                                  <h4 style={{ fontSize: "40px" }}>
                                    {token.GB}
                                  </h4>
                                </Card.Title>
                              </Card.Body>
                            </Card>
                          </Col>
                        </div>
                        {/* <div className="row mt-2 text-center d-flex justify-content-center align-items-center">
                    <div className="col-6 d-flex justify-content-right align-items-right">
                      <Card className="" style={{ width: "100%" }}>
                        <Card.Body>
                          <Card.Title>
                            <h4 style={{ fontSize: "40px" }}>{token.GM}</h4>
                            <strong>Total GM</strong>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center">
                      <Card className="" style={{ width: "100%" }}>
                        <Card.Body>
                          <h4 style={{ fontSize: "40px" }}>{token.RP}</h4>
                          <Card.Title>
                            <strong>Total PR</strong>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  </div> */}
                      </Col>
                    </div>
                  </Col>
                </form>
              </Col>
            </div>
          </div>
        </section>
      </FormContainer>
      {/* <Footer /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { blockchainData: state };
};
export default connect(mapStateToProps)(account);
