export const ADD_ADDRESS = 'ADD_ADDRESS'
export const ADD_USDT_CONTRACT = 'ADD_USDT_CONTRACT'
export const ADD_GB_CONTRACT = 'ADD_GB_CONTRACT'
export const ADD_GM_CONTRACT = 'ADD_GM_CONTRACT'
export const ADD_CB_CONTRACT = 'ADD_CB_CONTRACT'
export const ADD_RP_CONTRACT = 'ADD_RP_CONTRACT'
export const ADD_MAIN_CONTRACT = 'ADD_MAIN_CONTRACT'
export const CONNECT_USDT_CONTRACT = 'ADD_USDT_CONTRACT_CONNECT'
export const CONNECT_GB_CONTRACT = 'ADD_GB_CONTRACT_CONNECT'
export const CONNECT_GM_CONTRACT = 'ADD_GM_CONTRACT_CONNECT'
export const CONNECT_CB_CONTRACT = 'ADD_CB_CONTRACT_CONNECT'
export const CONNECT_RP_CONTRACT = 'ADD_RP_CONTRACT_CONNECT'
export const CONNECT_MAIN_CONTRACT = 'ADD_MAIN_CONTRACT_CONNECT'