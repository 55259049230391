import React, { Component } from "react";

import "../../assets/animated.css";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { BigNumber } from "ethers";

import { connect } from "react-redux";

const cbstaked = (_state) => {
  _state.blockchainData.blockchain.maincontract.connected
    .myCBStaked()
    .then((result) => {
      return result * 1e18;
    })
    .catch((error) => console.log(error.data.message));
};

const toClameCB90D = (_state) => {
  _state.blockchainData.blockchain.maincontract.connected
    .viewToClameTokens90D()
    .then((result) => {
      return result * 1e18;
    })
    .catch((error) => console.log(error));
};

const _90daysCompletionClame = (_state) => {
  _state.blockchainData.blockchain.maincontract.connected
    .T90DClame()
    .then((resopnse) => console.log(resopnse))
    .catch((error) => console.log(error));
};

class Responsive extends Component {
  dummyData = [
    {
      deadline: "December, 30, 2021",
      authorLink: "ItemDetail",
      nftLink: "ItemDetail",
      bidLink: "ItemDetail",
      authorImg: "./img/author/author-1.jpg",
      previewImg: "./img/items/static-1.jpg",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-10.jpg",
      previewImg: "./img/items/static-2.jpg",
      title: "Victoria",
      price: "0.06 ETH",
      bid: "1/22",
      likes: 80,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-11.jpg",
      previewImg: "./img/items/static-3.jpg",
      title: "Victoria",
      price: "0.05 ETH",
      bid: "1/11",
      likes: 97,
    },
    {
      deadline: "January, 1, 2022",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-12.jpg",
      previewImg: "./img/items/static-4.jpg",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-9.jpg",
      previewImg: "./img/items/anim-4.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
  ];
  stakedummyData = [
    {
      Daily: "0.20%",
      authorImg: "./img/author/author-2.jpg",
      daily: "0.50%",
      head2: "Daily Reward",
      range: "0.15% ~ 0.35%",
      balance: "You Have 0 $USDT",
      previewImg: "./img/items/static-6.jpg",
      title: "Flexible Term",
      StackAmount: "70 USDT",
    },
  ];
  daylockdummyData = [
    {
      Daily: "0.20%",
      authorImg: "./img/author/author-2.jpg",
      daily: "0.40%",
      previewImg: "./img/items/static-6.jpg",
      title: "90 Days Locked",
      StackAmount: "70 USDT",
    },
  ];

  constructor(props) {
    super(props);
    {
      props.stake
        ? (this.state = {
            nfts: this.stakedummyData.slice(0, 8),
            height: 75,
            flexibleregister: true,
            depositregister: true,
            flexibleamount: 0,
            daysamount: 0,
            stakeamount: 0,
          })
        : props.lock
        ? (this.state = {
            nfts: this.daylockdummyData.slice(0, 8),
            height: 0,
            flexibleamount: 0,
            flexibleregister: true,
            depositregister: true,
            daysamount: 0,
            stakeamount: 0,
          })
        : props.GM
        ? (this.state = {
            nfts: this.daylockdummyData.slice(0, 8),
            height: 0,
            flexibleamount: 0,
            gmamount: 0,
            daysamount: 0,
            stakeamount: 0,
          })
        : (this.state = {
            nfts: this.dummyData.slice(0, 8),
            flexibleamount: 0,
            daysamount: 0,
            height: 0,
            stakeamount: 0,
          });
    }
    this.handleplus = this.handleplus.bind(this);

    this.onImgLoad = this.onImgLoad.bind(this);
  }

  // loadMore = () => {
  //   let nftState = this.state.nfts;
  //   let start = nftState.length;
  //   let end = nftState.length + 4;
  //   {
  //     if (this.props.stake === "stake") {
  //       this.setState({
  //         nfts: [...nftState, ...this.stakedummyData.slice(start, end)],
  //       });
  //     }
  //     if (this.props.lock === "lock") {
  //       this.setState({
  //         nfts: [...nftState, ...this.daylockdummyData.slice(start, end)],
  //       });
  //     }
  //   }
  // };

  onImgLoad({ target: img }) {
    let currentHeight = this.state.height;
    if (currentHeight < img.offsetHeight) {
      this.setState({
        height: img.offsetHeight,
      });
    }
  }
  // componentDidMount() {
  //   this.flexTStakeAmount(this.props);
  // }

  handleplus(e, arg, operation) {
    e.preventDefault();
    if (
      arg == "stake" &&
      operation == "add" &&
      this.state.flexibleamount < 100
    ) {
      let values = 10 + this.state.flexibleamount;
      this.setState({
        flexibleamount: values,
      });
      console.log(values);
    }
    if (
      arg == "stake" &&
      operation == "min" &&
      this.state.flexibleamount >= 10
    ) {
      let values = this.state.flexibleamount - 10;
      this.setState({
        flexibleamount: values,
      });
      console.log(values);
    }
    if (arg == "days" && operation == "add" && this.state.daysamount < 1000) {
      let values = 100 + this.state.daysamount;
      this.setState({
        daysamount: values,
      });
      console.log(values);
    }
    if (arg == "days" && operation == "min" && this.state.daysamount >= 100) {
      let values = this.state.daysamount - 100;
      this.setState({
        daysamount: values,
      });
      console.log(values);
    }
  }
  _90daysStakingamount(_state) {
    _state.blockchainData.blockchain.maincontract.connected
      .my90daysStakeAmount()
      .then((result) => {
        const resultant = result * 1e18;
        return resultant;
      })
      .catch((error) => console.log(error));
  }
  stake90DaysTerm(_state, amount) {
    const amountvwti = BigNumber.from(amount).mul(BigNumber.from(10).pow(18));
    console.log("This is console in 90");
    console.log(_state.blockchainData);
    _state.blockchainData.blockchain.maincontract.connected
      .stake90D(amountvwti)
      .then((resopnse) => console.log(resopnse))
      .catch((error) => console.log(error));
  }
  cblocked(_state) {
    _state.blockchainData.blockchain.maincontract.connected
      .myLockedCBTokens()
      .then((result) => {
        return result * 1e18;
      })
      .catch((error) => console.log(error));
  }
  flexTStakeAmount(_state) {
    const staketheusdtAmount = 0;
    let { data } = _state.blockchainData.blockchain.maincontract.connected
      .stakeInFlexibleTerm()
      .then((result) => {
        staketheusdtAmount = result * 1e18;
        return staketheusdtAmount;
      })
      .catch((error) => console.log(error));
    console.log(data);
    return data;
  }
  stakeFlexibleTerm(_state, amount) {
    const amountvwti = BigNumber.from(amount).mul(BigNumber.from(10).pow(18));
    _state.blockchainData.blockchain.maincontract.connected
      .stakeFlexT(amountvwti)
      .then((resopnse) => console.log(resopnse))
      .catch((error) => console.log(error));
  }
  terminateFlexibleTerm(_state) {
    _state.blockchainData.blockchain.maincontract.connected
      .terminatFlexT()
      .then((resopnse) => console.log(resopnse))
      .catch((error) => console.log(error));
  }
  terminate90DaysTerm(_state) {
    _state.blockchainData.blockchain.maincontract.connected
      .stakeIn90DaysTesm()
      .then((resopnse) => console.log(resopnse))
      .catch((error) => console.log(error));
  }
  dailyReleaseFlexibleTerm(_state) {
    _state.blockchainData.blockchain.maincontract.connected
      .dailyReleaseFlexible()
      .then((resopnse) => console.log(resopnse))
      .catch((error) => console.log(error));
  }

  dailyRelease90DaysTerm(_state) {
    _state.blockchainData.blockchain.maincontract.connected
      .dailyRelease90days()
      .then((resopnse) => console.log(resopnse))
      .catch((error) => console.log(error));
  }
  handleFlexibleRegisterBtn() {
    this.setState({
      flexibleregister: !this.state.flexibleregister,
    });
  }
  handleLockRegisterBtn() {
    this.setState({
      depositregister: !this.state.depositregister,
    });
  }

  render() {
    return (
      <div className="col w-100 ">
        {this.state.nfts.map((nft, index) => (
          <div
            key={index}
            className="d-item  w-100 col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4 "
            style={{
              minWidth: this.props.width ? this.props.width : "100%",
            }}
          >
            <div className="nft__item  ">
              {this.props.stake ? (
                <>
                  <div className="nft__item_wrap mt-1 mb-5 justify-content-center text-center mt-5 p-3">
                    <h3>{nft.title}</h3>
                    <h4 className="mt-4   ">{nft.head2}</h4>
                    <h4 style={{ color: "#77AB59" }}>{nft.range}</h4>
                    <h5>You Have 0 $USDT</h5>
                    <div className="d-flex text-center justify-content-center mb-4">
                      <label
                        className="btn-main-2"
                        onClick={(e) => this.handleplus(e, "stake", "add")}
                      >
                        +
                      </label>

                      <Col className="col-4">
                        <InputGroup>
                          {" "}
                          <Form.Control
                            placeholder="10 "
                            className="ms-2 me-2"
                            min={10}
                            max={1000}
                            value={this.state.flexibleamount}
                            onChange={(e) => {
                              this.setState({
                                flexibleamount: e.target.value,
                              });
                              {
                                console.log(this.state.flexibleamount);
                              }
                            }}
                            style={{
                              display: "flex",
                              borderRadius: "none",
                              marginBottom: "0px",
                              justifyContent: "center",
                              textAlign: "center",
                              border: "2px solid #fff",
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <label
                        className="btn-main-2 "
                        onClick={(e) => this.handleplus(e, "stake", "min")}
                      >
                        -
                      </label>
                    </div>
                    <span className="mt-2" style={{ color: "white" }}>
                      Min 10 $USDT ~ Max 1000 $USDT
                    </span>
                    <Row>
                      {" "}
                      <Col className="col-12 d-flex">
                        {" "}
                        <button
                          className=" btn-main  w-100  mt-5 "
                          onClick={() => {
                            this.stakeFlexibleTerm(
                              this.props,
                              this.state.flexibleamount,
                              !this.state.flexibleregister
                            );
                          }}
                        >
                          <h4>
                            {this.state.flexibleregister
                              ? "Go Deposit & Register"
                              : "Stake $USDT"}
                          </h4>
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <div className="box-mim-2 text-center ">
                    <Col className="col-12  text-center  p-3">
                      <div className="  align-item-center text-center card-inner">
                        <h6>
                          Your $USDT Stacked :{" "}
                          {this.props.blockchainData.blockchain.maincontract
                            .connected != undefined ? (
                            <span>
                              {setTimeout(
                                () => this.flexTStakeAmount(this.props),
                                5000
                              )}
                            </span>
                          ) : (
                            <></>
                          )}{" "}
                          0 $USDT
                        </h6>
                        <h6>Your Daily Reward : 0 $GB</h6>
                        <h6>Your Unclaimed Reward : 0 $GB</h6>
                        <h6>Your Claimed Reward : 0 $GB</h6>
                      </div>
                    </Col>
                  </div>
                  <div>
                    {" "}
                    <div className="col-lg-12">
                      <div className="spacer-single"></div>

                      <button
                        type="submit"
                        onClick={() => {
                          this.dailyReleaseFlexibleTerm(this.props);
                        }}
                        className="btn-main w-100 lead m-auto"
                      >
                        <span>Claim Reward</span>
                      </button>
                      <span
                        onClick={() => {
                          this.terminateFlexibleTerm(this.props);
                        }}
                        className="btn-main w-100  mt-4 lead m-auto"
                      >
                        <span>Unstake</span>
                      </span>
                      {/* {this.props.stake === "stake" ? (
                        <Link
                          to="/flexible-deposit"
                          className=" w-100"
                          style={{
                            textDecoration: "none",
                            color: "#fff",
                          }}
                        >
                          <span>Stake $USDT</span>
                        </Link>
                      ) : this.props.lock === "lock" ? (
                        <Link
                          to="/days-deposit"
                          style={{
                            textDecoration: "none",
                            color: "#fff",
                          }}
                        >
                          <span></span>
                        </Link>
                      ) : (
                        <span></span>
                      )} */}
                    </div>
                  </div>
                </>
              ) : this.props.lock ? (
                <>
                  <Form action="">
                    <div
                      className="nft__item_wrap  mb-5 justify-content-center text-center mt-5 p-3"
                      style={{ height: `auto` }}
                    >
                      <h3>{nft.title}</h3>
                      <h4 className="mt-4  ">Daily Reward</h4>
                      <h4 className="mb-3" style={{ color: "#77AB59" }}>
                        0.35% ~ 0.55%
                      </h4>
                      <div
                        className="mb-5"
                        style={{
                          lineHeight: "2px",
                        }}
                      >
                        <p>You Stake 100 $ Get</p>
                        <p>Airdrop 150 $CB Locked</p>
                      </div>

                      <h6 className="mt-3 mb-3">You Have 0 $USDT</h6>
                      <div className="d-flex text-center justify-content-center ">
                        <label
                          className="btn-main-2"
                          onClick={(e) => this.handleplus(e, "days", "add")}
                        >
                          +
                        </label>
                        <Col className="col-4">
                          <InputGroup>
                            {" "}
                            <Form.Control
                              type="text"
                              min={100}
                              max={1000}
                              placeholder="10 "
                              className="ms-2 me-2"
                              value={this.state.daysamount}
                              onChange={(e) => {
                                this.setState({
                                  daysamount: e.target.value,
                                });
                                {
                                  console.log(this.state.daysamount);
                                }
                              }}
                              style={{
                                display: "flex",
                                borderRadius: "none",
                                marginBottom: "0px",
                                justifyContent: "center",
                                textAlign: "center",
                                border: "2px solid #fff",
                              }}
                            />
                          </InputGroup>
                        </Col>
                        <label
                          className="btn-main-2"
                          onClick={(e) => this.handleplus(e, "days", "min")}
                        >
                          -
                        </label>
                      </div>
                      <h6 className="mt-4 mb-3" style={{ color: "white" }}>
                        Min 100 $USDT ~ Unlimited $USDT
                      </h6>

                      <Row>
                        {" "}
                        <Col className="col-12 ">
                          {" "}
                          <button
                            type="submit"
                            className=" btn-main  w-100  mt-2"
                            onClick={() => {
                              this.stake90DaysTerm(
                                this.props,
                                this.state.daysamount,
                                !this.state.depositregister
                              );
                            }}
                          >
                            <h4>
                              {this.state.depositregister
                                ? "Go Deposit & Register"
                                : "Stake $USDT"}
                            </h4>
                          </button>
                        </Col>
                      </Row>
                    </div>
                    <Row className="ps-3 pe-3">
                      <div className="box-mim-3 text-center ">
                        <Col className="col-12  text-center  p-3">
                          <div className="  align-item-center text-center justify-content-center card-inner">
                            <h6>
                              You $USDT Stake :
                              {this.props.blockchainData.blockchain.maincontract
                                .connected != undefined ? (
                                setTimeout(() => {
                                  this._90daysStakingamount(this.props);
                                }, 5000)
                              ) : (
                                <></>
                              )}{" "}
                              0 $USDT
                            </h6>
                            <h6>
                              Your Get $CB Locked :
                              {this.props.blockchainData.blockchain.maincontract
                                .connected != undefined ? (
                                setTimeout(() => {
                                  this.cblocked(this.props);
                                }, 5000)
                              ) : (
                                <></>
                              )}{" "}
                              0 $CB
                            </h6>

                            <h6>Your Daily Reward : 4 SGB </h6>
                            <h6>Your Unclaimed Reward : 16 $CB </h6>
                            <h6>Your Claimed Reward : 0 $GB </h6>
                            <span></span>
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <div>
                      {" "}
                      <div className="col-lg-12">
                        <div className="spacer-single"></div>

                        <Button
                          type="submit"
                          // onClick={() => {
                          //   this.dailyRelease90DaysTerm(this.props);
                          // }}
                          className="btn-main w-100 lead m-auto"
                        >
                          <span>Claim Reward</span>
                        </Button>
                        <span
                          onClick={() => {
                            this.terminate90DaysTerm(this.props);
                          }}
                          className="btn-main w-100  mt-4 lead m-auto"
                        >
                          <span>Unstake</span>
                        </span>
                        {/* {this.props.stake === "stake" ? (
                        <Link
                          to="/flexible-deposit"
                          className=" w-100"
                          style={{
                            textDecoration: "none",
                            color: "#fff",
                          }}
                        >
                          <span>Stake $USDT</span>
                        </Link>
                      ) : this.props.lock === "lock" ? (
                        <Link
                          to="/days-deposit"
                          style={{
                            textDecoration: "none",
                            color: "#fff",
                          }}
                        >
                          <span></span>
                        </Link>
                      ) : (
                        <span></span>
                      )} */}
                      </div>
                    </div>
                  </Form>
                </>
              ) : this.props.GM ? (
                <>
                  <div
                    className="nft__item_wrap mt-1 mb-5  justify-content-center text-center mt-5 gw-wrapper"
                    style={{ height: `auto` }}
                  >
                    <h6 className="mb-2">Stake 100 $CB & get 5 $GM /daily</h6>
                    <h5>You Have 0 $USDT</h5>
                    <div className="d-flex text-center justify-content-center mb-4">
                      <button
                        className="btn-main-2"
                        onClick={this.buttonplusflexHandler}
                      >
                        +
                      </button>

                      <Col className="col-4">
                        <InputGroup>
                          {" "}
                          <Form.Control
                            placeholder="10 "
                            onChange={(e) => e.preventDefault()}
                            value={this.state.flexibleamount}
                            className="ms-2 me-2"
                            style={{
                              display: "flex",
                              borderRadius: "none",
                              marginBottom: "0px",
                              justifyContent: "center",
                              textAlign: "center",
                              border: "2px solid #fff",
                            }}
                          />
                        </InputGroup>
                      </Col>
                      <button
                        className="btn-main-2"
                        onClick={this.buttonsubflexHandler}
                      >
                        -
                      </button>
                    </div>

                    <Row>
                      {" "}
                      <Col className="col-12 d-flex">
                        {" "}
                        <button
                          className=" btn-main  w-100  mt-5 m-auto gw-btn"
                          onClick={() => {
                            this.stakeFlexibleTerm(
                              this.props,
                              this.state.flexibleamount
                            );
                          }}
                        >
                          <span>Stake $CB</span>
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <div className="box-mim-2 text-center ">
                    <Col className="col-12  text-center  p-2">
                      <div className="  align-item-center text-center card-inner">
                        <h6>You stake $CB : 0</h6>
                        <h6>You GM daily reward : 0</h6>
                        <h6>You unclaimed GM reward : 0</h6>
                        <h6>You claimed GM reward : 0</h6>
                        <span></span>
                      </div>
                    </Col>
                  </div>
                  <div>
                    {" "}
                    <div className="col-lg-12">
                      <div className="spacer-single"></div>

                      <span
                        onClick={() => {
                          this.dailyReleaseFlexibleTerm(this.props);
                        }}
                        className="btn-main w-100 lead m-auto gw-btn"
                      >
                        <span>Claim Reward</span>
                      </span>
                      <span
                        onClick={() => {
                          this.terminateFlexibleTerm(this.props);
                        }}
                        className="btn-main w-100  mt-4 lead m-auto gw-btn"
                      >
                        <span>Unstake</span>
                      </span>
                      {/* {this.props.stake === "stake" ? (
                      <Link
                        to="/flexible-deposit"
                        className=" w-100"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                        }}
                      >
                        <span>Stake $USDT</span>
                      </Link>
                    ) : this.props.lock === "lock" ? (
                      <Link
                        to="/days-deposit"
                        style={{
                          textDecoration: "none",
                          color: "#fff",
                        }}
                      >
                        <span></span>
                      </Link>
                    ) : (
                      <span></span>
                    )} */}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="nft__item_wrap mt-1 mb-5 justify-content-center text-center p-5"
                  style={{ height: `${this.state.height}px` }}
                >
                  <h3>
                    {nft.title}

                    <h6 className="mt-4 mb-4">
                      Stake Amount
                      <h4 className="mt-2">{nft.StackAmount}</h4>
                    </h6>
                  </h3>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { blockchainData: state };
}

export default connect(mapStateToProps)(Responsive);
