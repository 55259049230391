import React from "react";

const authorlist = () => (
  <div>
    <ol className="author_list">
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-1.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">3.2 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-2.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">2.8 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-3.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">2.5 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-4.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">2.2 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-5.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">1.9 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-6.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">1.6 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-7.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">1.5 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-8.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">1.3 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-9.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">0.9 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-10.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">0.8 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-11.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">0.6 ETH</span>
        </div>
      </li>
      <li>
        <div className="author_list_pp">
          <span onClick={() => window.open("", "_self")}>
            <img className="lazy" src="./img/author/author-12.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div>
        <div className="author_list_info">
          <span onClick={() => window.open("", "_self")}>
            Ahmad Software and Technology
          </span>
          <span className="bot">0.5 eth</span>
        </div>
      </li>
    </ol>
  </div>
);
export default authorlist;
