import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Clock from "./Clock";

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 260px;
  overflow: hidden;
  border-radius: 8px;
`;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

export default class Responsive extends Component {
  dummyData = [
    {
      deadline: "December, 30, 2021",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-1.jpg",
      previewImg: "./img/items/static-1.jpg",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-10.jpg",
      previewImg: "./img/items/static-2.jpg",
      title: "Victoria",
      price: "0.06 ETH",
      bid: "1/22",
      likes: 80,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-11.jpg",
      previewImg: "./img/items/static-3.jpg",
      title: "Victoria",
      price: "0.05 ETH",
      bid: "1/11",
      likes: 97,
    },
    {
      deadline: "January, 1, 2022",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-12.jpg",
      previewImg: "./img/items/static-4.jpg",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-9.jpg",
      previewImg: "./img/items/anim-4.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "January, 15, 2022",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-2.jpg",
      previewImg: "./img/items/anim-2.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-3.jpg",
      previewImg: "./img/items/anim-1.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-4.jpg",
      previewImg: "./img/items/anim-5.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "January, 3, 2022",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-5.jpg",
      previewImg: "./img/items/anim-3.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-7.jpg",
      previewImg: "./img/items/static-5.jpg",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-6.jpg",
      previewImg: "./img/items/anim-6.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "January, 10, 2022",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-8.jpg",
      previewImg: "./img/items/anim-7.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-9.jpg",
      previewImg: "./img/items/static-6.jpg",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
    {
      deadline: "January, 10, 2022",
      authorLink: "#",
      nftLink: "#",
      bidLink: "#",
      authorImg: "./img/author/author-12.jpg",
      previewImg: "./img/items/anim-8.webp",
      title: "Victoria",
      price: "0.08 ETH",
      bid: "1/20",
      likes: 50,
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      deadline: "January, 10, 2022",
      deadline1: "February, 10, 2022",
      deadline2: "February, 1, 2022",
    };
  }

  render() {
    var settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: 300,
      responsive: [
        {
          breakpoint: 1900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    };
    return (
      <div className="nft">
        <Slider {...settings}>
          <CustomSlide className="itm" index={1}>
            <div className="d-item">
              <div className="nft__item">
                <div className="nft__item_wrap">
                  <img
                    src="./img/items/static-1.jpg"
                    className="lazy nft__item_preview img-fluid"
                    alt=""
                  />
                </div>
                <div className="box-mim text-center pt-3 pb-2 mt-2">
                  <span onClick={() => window.open("/#", "_self")}>
                    <h4>Victoria</h4>
                  </span>
                  <div className="nft__item_price">
                    0.08 ETH<span>1/20</span>
                  </div>
                </div>
                <div className="  d-flex justify-content-between mt-2">
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">SOLD</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">15</div>
                    </div>
                  </div>
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">TIME</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">12D: 13H</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  justify-content-between mt-2">
                  <div className="minus-box">
                    <i
                      class="fa fa-minus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="target-box text-center">
                    <i
                      class="fa fa-ticket"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    &nbsp; Tickets
                  </div>
                  <div className="minus-box">
                    <i
                      class="fa fa-plus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={2}>
            <div className="d-item">
              <div className="nft__item">
                <div className="nft__item_wrap">
                  <img
                    src="./img/items/static-2.jpg"
                    className="lazy nft__item_preview img-fluid"
                    alt=""
                  />
                </div>
                <div className="box-mim text-center pt-3 pb-2 mt-2">
                  <span onClick={() => window.open("/#", "_self")}>
                    <h4>Victoria</h4>
                  </span>
                  <div className="nft__item_price">
                    0.06 ETH<span>1/22</span>
                  </div>
                </div>
                <div className="  d-flex justify-content-between mt-2">
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">SOLD</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">15</div>
                    </div>
                  </div>
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">TIME</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">12D: 13H</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  justify-content-between mt-2">
                  <div className="minus-box">
                    <i
                      class="fa fa-minus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="target-box text-center">
                    <i
                      class="fa fa-ticket"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    &nbsp; Tickets
                  </div>
                  <div className="minus-box">
                    <i
                      class="fa fa-plus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={3}>
            <div className="d-item">
              <div className="nft__item">
                <div className="nft__item_wrap">
                  <img
                    src="./img//items/static-3.jpg"
                    className="lazy nft__item_preview img-fluid"
                    alt=""
                  />
                </div>
                <div className="box-mim text-center pt-3 pb-2 mt-2">
                  <span onClick={() => window.open("/#", "_self")}>
                    <h4>Victoria</h4>
                  </span>
                  <div className="nft__item_price">
                    0.05 ETH<span>1/11</span>
                  </div>
                </div>
                <div className="  d-flex justify-content-between mt-2">
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">SOLD</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">15</div>
                    </div>
                  </div>
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">TIME</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">12D: 13H</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  justify-content-between mt-2">
                  <div className="minus-box">
                    <i
                      class="fa fa-minus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="target-box text-center">
                    <i
                      class="fa fa-ticket"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    &nbsp; Tickets
                  </div>
                  <div className="minus-box">
                    <i
                      class="fa fa-plus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={4}>
            <div className="d-item">
              <div className="nft__item">
                <div className="nft__item_wrap">
                  <img
                    src="./img/items/static-4.jpg"
                    className="lazy nft__item_preview"
                    alt=""
                  />
                </div>
                <div className="box-mim text-center pt-3 pb-2 mt-2">
                  <span onClick={() => window.open("/#", "_self")}>
                    <h4>Victoria</h4>
                  </span>
                  <div className="nft__item_price">
                    0.02 ETH<span>1/15</span>
                  </div>
                </div>
                <div className="  d-flex justify-content-between mt-2">
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">SOLD</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">15</div>
                    </div>
                  </div>
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">TIME</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">12D: 13H</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  justify-content-between mt-2">
                  <div className="minus-box">
                    <i
                      class="fa fa-minus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="target-box text-center">
                    <i
                      class="fa fa-ticket"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    &nbsp; Tickets
                  </div>
                  <div className="minus-box">
                    <i
                      class="fa fa-plus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={5}>
            <div className="d-item">
              <div className="nft__item">
                <div className="nft__item_wrap">
                  <img
                    src="./img/items/anim-4.webp"
                    className="lazy nft__item_preview"
                    alt=""
                  />
                </div>
                <div className="box-mim text-center pt-3 pb-2 mt-2">
                  <span onClick={() => window.open("/#", "_self")}>
                    <h4>Victoria</h4>
                  </span>
                  <div className="nft__item_price">
                    0.06 ETH<span>1/20</span>
                  </div>
                </div>
                <div className="  d-flex justify-content-between mt-2">
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">SOLD</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">15</div>
                    </div>
                  </div>
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">TIME</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">12D: 13H</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  justify-content-between mt-2">
                  <div className="minus-box">
                    <i
                      class="fa fa-minus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="target-box text-center">
                    <i
                      class="fa fa-ticket"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    &nbsp; Tickets
                  </div>
                  <div className="minus-box">
                    <i
                      class="fa fa-plus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide className="itm" index={6}>
            <div className="d-item">
              <div className="nft__item">
                <div className="de_countdown">
                  <Clock deadline={this.state.deadline2} />
                </div>

                <div className="nft__item_wrap">
                  <img
                    src="./img/items/anim-2.webp"
                    className="lazy nft__item_preview"
                    alt=""
                  />
                </div>
                <div className="box-mim text-center pt-3 pb-2 mt-2">
                  <span onClick={() => window.open("/#", "_self")}>
                    <h4>Victoria</h4>
                  </span>
                  <div className="nft__item_price">
                    0.03 ETH<span>1/24</span>
                  </div>
                </div>
                <div className="  d-flex justify-content-between mt-2">
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">SOLD</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">15</div>
                    </div>
                  </div>
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">TIME</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">12D: 13H</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  justify-content-between mt-2">
                  <div className="minus-box">
                    <i
                      class="fa fa-minus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="target-box text-center">
                    <i
                      class="fa fa-ticket"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    &nbsp; Tickets
                  </div>
                  <div className="minus-box">
                    <i
                      class="fa fa-plus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </CustomSlide>
        </Slider>
      </div>
    );
  }
}
