import React from "react";
import { Link } from "@reach/router";

const footer = () => (
  <footer className="footer-light">
    <div className="container">
      <div className="row d-flex justify-content-between">
        <div className="col-md-2 d-flex align-items-center">
          <Link to="/">
            <img
              src="./img/logo-3.png"
              className="img-fluid d-block"
              alt="#"
              style={{
                height: "80px",
              }}
            />{" "}
            <span className="ms-2 fs-5 text-white"></span>
          </Link>
        </div>

        <div className="col-md-3 text-white">
          <span>Follow us in social media: </span>
          <div className="mt-2">
            <i className="fa fa-facebook fa-lg "></i>
            <i className="fa fa-twitter fa-lg ms-3"></i>
            <i className="fa fa-instagram fa-lg ms-3"></i>
          </div>
        </div>
        <div className="col-md-12 text-center mt-4 mb-2">
          <span className="copy">
            &copy; Copyright 2023 - Victoria Gold Bond
          </span>
        </div>
      </div>
    </div>
  </footer>
);
export default footer;
