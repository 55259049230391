import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import {
  Button,
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import FormContainer from "./FormContainer";
import { connect } from "react-redux";
import { BigNumber } from "ethers";

const Exchange = (props) => {
  const [amount, setamount] = useState(0);
  const [toamount, settoamount] = useState(0);
  const [from, setfrom] = useState("GB");
  const [to, setto] = useState("USDT");

  const swapToken = (e) => {
    e.preventDefault();

    if (from === "GB" && to === "USDT") {
      const amountInWei = BigNumber.from(amount).mul(
        BigNumber.from(10).pow(18)
      );

      props.blockchainData.blockchain.gbcontract.connected
        .increaseAllowance(
          "0xB5Cb6958363FA4D83DfBa4dB768B4C38D532E3DA",
          amountInWei
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));

      props.blockchainData.blockchain.maincontract.connected
        .swapGBtoUSDT(amountInWei)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
    } else if (from === "CB" && to === "USDT") {
      const amountInWei = BigNumber.from(amount).mul(
        BigNumber.from(10).pow(18)
      );

      props.blockchainData.blockchain.cbcontract.connected
        .increaseAllowance(
          "0xB5Cb6958363FA4D83DfBa4dB768B4C38D532E3DA",
          amountInWei
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));

      props.blockchainData.blockchain.maincontract.connected
        .swapCBtoUSDT(amountInWei)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
    } else {
      console.log("token not found");
    }
  };

  useEffect(() => {
    settoamount(amount);
  }, [amount]);

  return (
    <div className=" register-main task-container d-block">
      <section className="container ">
        <div className="row ">
          <div className="spacer-double"></div>
          <Col>
            <h2 className="text-center mt-5">Exchange</h2>

            <div className="spacer-10"></div>
            <form
              name="contactForm"
              id="contact_form"
              className="form-border justify-content-center"
              action="#"
            >
              <div
                className="row text-center align-item-center justify-content-center"
                style={{ marginBottom: "2rem", marginTop: "4rem" }}
              >
                {/* <Col className=" d-flex justify-content-center align-items-center">
                  <Card className="p-3" style={{ width: "22rem" }}>
                    <Card.Body>
                      <Card.Title>
                        <strong>CH</strong>
                      </Card.Title>
                      <Card.Title>
                        <strong>Balance</strong>
                      </Card.Title>
                    </Card.Body>
                    <h3 style={{ fontSize: "37px" }}>0</h3>
                  </Card>
                </Col> */}
                {/* <Col
                  className=" d-flex justify-content-center align-items-center"
                  style={{
                    margin: "auto",
                    width: "50%",
                  }}
                >
                  <Card className="p-3" style={{ width: "22rem" }}>
                    <Card.Body>
                      <Card.Title>
                        <strong>MP</strong>
                      </Card.Title>
                      <Card.Title>
                        <strong>Reserve</strong>
                      </Card.Title>
                    </Card.Body>
                    <h3 style={{ fontSize: "40px" }}>0</h3>
                  </Card>
                </Col> */}
              </div>
              <Col
                className="border p-4 text-center"
                style={{
                  borderRadius: "20px",
                }}
              >
                <div
                  className="row "
                  style={{ marginBottom: "2rem", marginTop: "4rem" }}
                >
                  <Col>
                    <div className="row text-center"></div>
                    <div className="row mt-4 text-center d-flex justify-content-center align-items-center ">
                      <Col
                        className="col-5  justify-content-right align-items-right  border p-2"
                        md={12}
                        sm={12}
                        lg={5}
                        xs={12}
                        xl={5}
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <h5 className="d-block">Swap you $GB get $USDT</h5>

                        <Card.Title
                          style={{
                            backgroundColor: "gray",
                            borderRadius: "20px",
                          }}
                        >
                          <div className="row ">
                            <Col className="  d-flex justify-content-center align-items-center mb-3">
                              <Card
                                className="p-3 "
                                style={{ width: "100%", border: "none" }}
                              >
                                <Col className="col-12 d-flex">
                                  <Col>
                                    <p className=" text-start exchange-font-head">
                                      From :
                                    </p>
                                  </Col>
                                  <h6
                                    as="h3"
                                    className=" text-end me-5  exchange-font-head"
                                  >
                                    Balance : 0
                                  </h6>
                                </Col>
                                <Col className="col-12 d-flex">
                                  <Col className="text-start">
                                    <span
                                      as="h3"
                                      className="  ms-5 "
                                      style={{
                                        fontSize: "22px",
                                      }}
                                    >
                                      $GB
                                    </span>
                                  </Col>
                                  <h6 as="h3" className=" text-end ">
                                    <input
                                      type="text"
                                      className="text-center"
                                      placeholder="0"
                                      style={{
                                        color: "white",
                                        backgroundColor: "black",
                                        border: "none",
                                        borderRadius: "20px",
                                        padding: "4px",
                                        width: "60%",
                                      }}
                                    />
                                  </h6>
                                </Col>
                                <Col className="col-12 d-flex">
                                  <Col>
                                    <h6 className=" text-end exchange-font-foot ">
                                      1$GB=1 USDT ( 1$CB Tax )
                                    </h6>
                                  </Col>
                                </Col>
                              </Card>
                            </Col>
                          </div>
                        </Card.Title>
                        <Card.Title>
                          <div className="row ">
                            <Col className="  d-flex justify-content-center align-items-center mb-3">
                              <Card
                                className="p-auto "
                                style={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  border: "none",
                                }}
                              >
                                <Card.Body className="">
                                  <Card.Title className=" ">
                                    <Col className="col-12 d-flex">
                                      <Col>
                                        <h6
                                          as="h3"
                                          className=" text-start exchange-font-head"
                                        >
                                          From :
                                        </h6>
                                      </Col>
                                      <h6
                                        as="h3"
                                        className=" text-end exchange-font-head me-4 "
                                      >
                                        Balance : 0
                                      </h6>
                                    </Col>
                                    <Col className="col-12 d-flex mt-2">
                                      <Col>
                                        <h6
                                          as="h3"
                                          className=" text-start ms-4  exchange-font-head"
                                        >
                                          USDT
                                        </h6>
                                      </Col>
                                      <h6 as="h3" className=" text-end ">
                                        <input
                                          type="text"
                                          className="text-center"
                                          placeholder="0"
                                          style={{
                                            color: "white",
                                            backgroundColor: "black",
                                            border: "none",
                                            padding: "4px",
                                            borderRadius: "20px",
                                            width: "70%",
                                          }}
                                        />
                                      </h6>
                                    </Col>
                                  </Card.Title>
                                </Card.Body>
                              </Card>
                            </Col>
                          </div>
                        </Card.Title>
                        <div className="row  ">
                          <div className="col-md-12 text-center ">
                            <div className="">
                              <input
                                type="submit"
                                id="send_message"
                                value="Swap"
                                className="btn btn-main color-2"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <div className="col-1"></div>
                      <Col
                        className="col-5 mt-2 justify-content-right align-items-right  border p-2"
                        md={12}
                        xs={12}
                        sm={12}
                        lg={5}
                        xl={5}
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <h5 className="d-block mt-2">Swap you $CB get $USDT</h5>

                        <Card
                          className=""
                          style={{ width: "100%", border: "none" }}
                        >
                          <Card.Title

                            style={{
                              backgroundColor: "gray",
                              borderRadius: "20px",
                            }}
                          >
                            <div className="row ">
                              <Col className="  d-flex justify-content-center align-items-center mb-3">
                                <Card
                                  className="p-auto "
                                  style={{ width: "100%", border: "none" }}
                                >
                                  <Card.Body className="">
                                    <Card.Title className=" ">
                                      <Col className="col-12 d-flex">
                                        <Col>
                                          <h6
                                            as="h3"
                                            className=" text-start exchange-font-head"
                                          >
                                            From :
                                          </h6>
                                        </Col>
                                        <h6
                                          as="h3"
                                          className=" text-end me-4  exchange-font-head "
                                        >
                                          Balance : 0
                                        </h6>
                                      </Col>
                                      <Col className="col-12 d-flex mt-2">
                                        <Col>
                                          <h6
                                            as="h3"
                                            className=" text-start ms-4 "
                                            style={{
                                              fontSize: "22px",
                                            }}
                                          >
                                            $GB
                                          </h6>
                                        </Col>
                                        <h6 as="h3" className=" text-end ">
                                          <input
                                            type="text"
                                            className="text-center"
                                            placeholder="0"
                                            style={{
                                              color: "white",
                                              backgroundColor: "black",
                                              border: "none",
                                              padding: "4px",
                                              borderRadius: "20px",
                                              width: "60%",
                                            }}
                                          />
                                        </h6>
                                      </Col>
                                      <Col className="col-12 d-flex">
                                        <Col>
                                          <h6 className=" text-end exchange-font-foot ">
                                            1$GB=1 USDT ( 1$CB Tax )
                                          </h6>
                                        </Col>
                                      </Col>
                                    </Card.Title>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </div>
                          </Card.Title>
                          <Card.Title>
                            <div className="row ">
                              <Col className="  d-flex justify-content-center align-items-center mb-3">
                                <Card
                                  className="p-auto "
                                  style={{
                                    width: "100%",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <Card.Body className="">
                                    <Card.Title className=" ">
                                      <Col className="col-12 d-flex">
                                        <Col>
                                          <h6
                                            as="h3"
                                            className=" text-start exchange-font-head"
                                          >
                                            From :
                                          </h6>
                                        </Col>
                                        <h6
                                          as="h3"
                                          className=" text-end me-4 exchange-font-head"
                                        >
                                          Balance : 0
                                        </h6>
                                      </Col>
                                      <Col className="col-12 d-flex mt-2">
                                        <Col>
                                          <h6
                                            as="h3"
                                            className=" text-start ms-4 exchange-font-head "
                                          >
                                            USDT
                                          </h6>
                                        </Col>
                                        <h6 as="h3" className=" text-end ">
                                          <input
                                            type="text"
                                            className="text-center"
                                            placeholder="0"
                                            style={{
                                              color: "white",
                                              backgroundColor: "black",
                                              border: "none",
                                              padding: "4px",
                                              borderRadius: "20px",
                                              width: "70%",
                                            }}
                                          />
                                        </h6>
                                      </Col>
                                    </Card.Title>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </div>
                          </Card.Title>
                          <div className="row  ">
                            <div className="col-md-12 text-center ">
                              <div className="">
                                <input
                                  type="submit"
                                  id="send_message"
                                  value="Swap"
                                  className="btn btn-main color-2"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </div>
                    {/* <div className="row mt-2 text-center d-flex justify-content-center align-items-center">
                    <div className="col-6 d-flex justify-content-right align-items-right">
                      <Card className="" style={{ width: "100%" }}>
                        <Card.Body>
                          <Card.Title>
                            <h4 style={{ fontSize: "40px" }}>{token.GM}</h4>
                            <strong>Total GM</strong>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center">
                      <Card className="" style={{ width: "100%" }}>
                        <Card.Body>
                          <h4 style={{ fontSize: "40px" }}>{token.RP}</h4>
                          <Card.Title>
                            <strong>Total PR</strong>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  </div> */}
                  </Col>
                </div>
              </Col>
            </form>
          </Col>
          {/* <CarouselCollection/> */}
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { blockchainData: state };
};

export default connect(mapStateToProps)(Exchange);
