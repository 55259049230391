import React, { useEffect, useState } from "react";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import "./styles.css";
import { Button, CardColumns, Dropdown, header } from "react-bootstrap";

import { Link } from "@reach/router";
import useOnclickOutside from "react-cool-onclickoutside";
import { connect, useSelector } from "react-redux";
import { setState } from "../../redux/actions/BlockchainActions";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { HoverMode } from "react-tsparticles";
import { usdtBalance } from "../../blockchain/functions";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

const Header = function (props) {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [walletaddress, setwalletaddress] = useState("0x21312321");

  const handleBtnClick = (): void => {
    setOpenMenu(!openMenu);
  };
  const handleBtnClick1 = (): void => {
    setOpenMenu1(!openMenu1);
  };
  const handleBtnClick2 = (): void => {
    setOpenMenu2(!openMenu2);
  };
  const handleBtnClick3 = (): void => {
    setOpenMenu3(!openMenu3);
  };
  const closeMenu = (): void => {
    setOpenMenu(false);
  };
  const closeMenu1 = (): void => {
    setOpenMenu1(false);
  };
  const closeMenu2 = (): void => {
    setOpenMenu2(false);
  };
  const closeMenu3 = (): void => {
    setOpenMenu3(false);
  };
  const ref = useOnclickOutside(() => {
    closeMenu();
  });
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });
  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });
  const ref3 = useOnclickOutside(() => {
    closeMenu3();
  });

  const [showmenu, btn_icon] = useState(false);
  const [balanceusdt, setbalanceusdt] = useState(0);
  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      // if (window.pageYOffset > sticky) {
      //   header.classList.add("sticky");
      //   totop.classList.add("show");
      // } else {
      //   header.classList.remove("sticky");
      //   totop.classList.remove("show");
      // }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (props.blockchainData.blockchain.account.address != "") {
        setwalletaddress(props.blockchainData.blockchain.account.address);
        console.log(`wallet address ${walletaddress}`);
      }
      if (props.blockchainData.blockchain.maincontract.connected != undefined) {
        props.blockchainData.blockchain.maincontract.connected
          .myUSDTBalance()
          .then((result) => {
            setbalanceusdt(`balance ${result / 1e18}`);
            console.log(result / 1e18);
          })
          .catch((error) => console.log(error));
      }
    }, 5000);

    // console.log("This is usdtbalance", usdtBalance);
  }, [props]);

  return (
    <header id="myHeader" className="navbar white  ">
      <div className="container">
        <div className="row w-100-nav ">
          <div className="logo px-0">
            <div className="navbar-title navbar-item d-flex align-items-center mt-3">
              <Link to="/">
                <img
                  src="./img/logo-3.png"
                  className="img-fluid "
                  alt="#"
                  style={{
                    height: "80px",
                  }}
                />
              </Link>
            </div>
          </div>

          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu && (
                <div className="menu">
                  <div className="navbar-item">
                    <Link to="/register">
                      <span>Register</span>
                      <span className="lines"></span>
                    </Link>
                  </div>
                  <div className="navbar-item">
                    <div ref={ref}>
                      <div
                        className="dropdown-custom  btn"
                        onClick={handleBtnClick}
                      >
                        <Link to="/task-list">
                          <span>Staking</span>
                          <span className="lines"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="navbar-item">
                    <div ref={ref1}>
                      <div
                        className="dropdown-custom  btn"
                        onClick={handleBtnClick1}
                      >
                        <Link to="/exchange">
                          <span>Exchange</span>
                          <span className="lines"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="navbar-item">
                    <div ref={ref2}>
                      <div
                        className="dropdown-custom  btn"
                        onClick={handleBtnClick2}
                      >
                        <Link to="/account">
                          <span>Account</span>
                          <span className="lines"></span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="navbar-item">
                    <div ref={ref2}>
                      <div
                        className="dropdown-custom  btn"
                        onMouseEnter={handleBtnClick2}
                        onMouseLeave={closeMenu2}
                      >
                        <Link to="/token">
                          <span>Gm Token</span>
                          <span className="lines"></span>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {props.blockchainData.blockchain.account.address === "" ? (
                    <>
                      {" "}
                      <Link
                        to="/wallet"
                        className="btn-main p-2 ps-3 pe-3 header-btn"
                      >
                        Connect
                      </Link>
                    </>
                  ) : (
                    <>
                      <div className=" mt-4 ">
                        <div className="btn-main  ">
                          {/* {props.blockchainData.blockchain.account.address.substring(
                          0,
                          7
                        ) + "..."} */}
                          <span className="header-btn ">
                            {props.blockchainData.blockchain.account.address.slice(
                              0,
                              5
                            ) +
                              "..." +
                              props.blockchainData.blockchain.account.address.slice(
                                38,
                                42
                              )}
                          </span>
                          <Dropdown
                            style={{
                              display: "inline",
                            }}
                          >
                            {""}{" "}
                            <Dropdown.Toggle
                              className="dropdown-toggler btn-main"
                              style={{
                                display: "inline",
                                padding: "10px",
                                backgroundColor: "none",

                                border: "none",
                              }}
                            />
                            <div className="header-dropdown-main">
                              <Dropdown.Menu
                                className="header-dropdown-menu "
                                style={{
                                  position: "absolute",
                                  right: "100px",
                                }}
                              >
                                <div>
                                  <h4 className="colorize p-2">
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="1em"
                                      viewBox="0 0 640 512"
                                      className="d-inline pe-2"
                                    >
                                      <path d="M384 32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H320 128c-17.7 0-32-14.3-32-32s14.3-32 32-32H288V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32zm55.6 288H584.4L512 195.8 439.6 320zM512 416c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416zM126.8 195.8L54.4 320H199.3L126.8 195.8zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1z" />
                                    </svg>
                                    Balance
                                  </h4>
                                  <span
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    {balanceusdt === 0 ? (
                                      <p>{0}</p>
                                    ) : (
                                      <p>{balanceusdt}</p>
                                    )}
                                  </span>
                                </div>

                                <div>
                                  <Link to="/deposit-all">
                                    <h5
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      <svg
                                        className="me-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 640 512"
                                      >
                                        <path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64H337.9c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64h64V320zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" />
                                      </svg>
                                      <span className="ms-2"> Deposit</span>
                                    </h5>
                                  </Link>
                                </div>
                                <div>
                                  <Link to="/withdraw-all ">
                                    <h5
                                      style={{
                                        color: "black",
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="me-2 w-30"
                                        height="1em"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M320 96H192L144.6 24.9C137.5 14.2 145.1 0 157.9 0H354.1c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128H320c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96H96c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4l0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V424c0 11 9 20 20 20s20-9 20-20V410.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l0 0-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V216z" />
                                      </svg>
                                      Withdraw
                                    </h5>
                                  </Link>
                                </div>
                              </Dropdown.Menu>
                            </div>
                          </Dropdown>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </Breakpoint>

            <Breakpoint xl>
              <div className="menu">
                <div className="navbar-item">
                  <Link to="/register">
                    <span>Register</span>
                    <span className="lines"></span>
                  </Link>
                </div>
                <div className="navbar-item">
                  <div ref={ref}>
                    <div
                      className="dropdown-custom  btn"
                      onMouseEnter={handleBtnClick}
                      onMouseLeave={closeMenu}
                    >
                      <Link to="/task-list">
                        <span>Staking</span>
                      </Link>
                      <span className="lines"></span>
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div ref={ref1}>
                    <div
                      className="dropdown-custom  btn"
                      onMouseEnter={handleBtnClick1}
                      onMouseLeave={closeMenu1}
                    >
                      <Link to="/exchange">
                        <span>Exchange</span>
                      </Link>
                      <span className="lines"></span>
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div ref={ref2}>
                    <div
                      className="dropdown-custom  btn"
                      onMouseEnter={handleBtnClick2}
                      onMouseLeave={closeMenu2}
                    >
                      <Link to="/account">
                        <span>Account</span>
                      </Link>
                      <span className="lines"></span>
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <div ref={ref2}>
                    <div
                      className="dropdown-custom  btn"
                      onMouseEnter={handleBtnClick2}
                      onMouseLeave={closeMenu2}
                    >
                      <Link to="/token">
                        <span>Gm Token</span>
                      </Link>
                      <span className="lines"></span>
                    </div>
                  </div>
                </div>

                {props.blockchainData.blockchain.account.address === "" ? (
                  <>
                    {" "}
                    <div className="mainside">
                      <div className="mainside mt-4  ">
                        <Link to="/wallet" className="btn-main ">
                          Connect
                        </Link>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" mt-4 ">
                      <div className="btn-main  ">
                        {/* {props.blockchainData.blockchain.account.address.substring(
                          0,
                          7
                        ) + "..."} */}
                        <span className="header-btn">
                          {props.blockchainData.blockchain.account.address.slice(
                            0,
                            5
                          ) +
                            "..." +
                            props.blockchainData.blockchain.account.address.slice(
                              38,
                              42
                            )}
                        </span>
                        <Dropdown
                          style={{
                            display: "inline",
                            width: "100%",
                          }}
                        >
                          {""}{" "}
                          <Dropdown.Toggle
                            className="dropdown-toggler btn-main"
                            style={{
                              display: "inline",
                              padding: "10px",
                              backgroundColor: "none",

                              border: "none",
                            }}
                          />
                          <div className="header-dropdown-main">
                            <Dropdown.Menu className="header-dropdown-menu ">
                              <div>
                                <h4 className="colorize p-2">
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="1em"
                                    viewBox="0 0 640 512"
                                    className="d-inline pe-2"
                                  >
                                    <path d="M384 32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H320 128c-17.7 0-32-14.3-32-32s14.3-32 32-32H288V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32zm55.6 288H584.4L512 195.8 439.6 320zM512 416c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416zM126.8 195.8L54.4 320H199.3L126.8 195.8zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1z" />
                                  </svg>
                                  Balance
                                </h4>
                                <span
                                  style={{
                                    color: "black",
                                  }}
                                >
                                  {balanceusdt === 0 ? (
                                    <p>{0}</p>
                                  ) : (
                                    <p>{balanceusdt}</p>
                                  )}
                                </span>
                              </div>

                              <div>
                                <Link to="/deposit-all">
                                  <h5
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    <svg
                                      className="me-2"
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="1em"
                                      viewBox="0 0 640 512"
                                    >
                                      <path d="M535 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l64 64c4.5 4.5 7 10.6 7 17s-2.5 12.5-7 17l-64 64c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l23-23L384 112c-13.3 0-24-10.7-24-24s10.7-24 24-24l174.1 0L535 41zM105 377l-23 23L256 400c13.3 0 24 10.7 24 24s-10.7 24-24 24L81.9 448l23 23c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L7 441c-4.5-4.5-7-10.6-7-17s2.5-12.5 7-17l64-64c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zM96 64H337.9c-3.7 7.2-5.9 15.3-5.9 24c0 28.7 23.3 52 52 52l117.4 0c-4 17 .6 35.5 13.8 48.8c20.3 20.3 53.2 20.3 73.5 0L608 169.5V384c0 35.3-28.7 64-64 64H302.1c3.7-7.2 5.9-15.3 5.9-24c0-28.7-23.3-52-52-52l-117.4 0c4-17-.6-35.5-13.8-48.8c-20.3-20.3-53.2-20.3-73.5 0L32 342.5V128c0-35.3 28.7-64 64-64zm64 64H96v64c35.3 0 64-28.7 64-64zM544 320c-35.3 0-64 28.7-64 64h64V320zM320 352a96 96 0 1 0 0-192 96 96 0 1 0 0 192z" />
                                    </svg>
                                    <span className="ms-2"> Deposit</span>
                                  </h5>
                                </Link>
                              </div>
                              <div>
                                <Link to="/withdraw-all ">
                                  <h5
                                    style={{
                                      color: "black",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="me-2 w-30"
                                      height="1em"
                                      viewBox="0 0 512 512"
                                    >
                                      <path d="M320 96H192L144.6 24.9C137.5 14.2 145.1 0 157.9 0H354.1c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128H320c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96H96c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4l0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V424c0 11 9 20 20 20s20-9 20-20V410.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l0 0-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V216z" />
                                    </svg>
                                    Withdraw
                                  </h5>
                                </Link>
                              </div>
                            </Dropdown.Menu>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <button className="nav-icon mt-4" onClick={() => btn_icon(!showmenu)}>
            <div className="menu-line white "></div>
            <div className="menu-line1 white"></div>
            <div className="menu-line2 white"></div>
          </button>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  blockchainData: state,
});

export default connect(mapStateToProps)(Header);
