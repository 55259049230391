import React, { useEffect, useState } from "react";
import BN from "bn";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { BigNumber } from "ethers";
const DepositAll = (props) => {
  console.log(props.blockchainData);

  const [amount, setamount] = useState();

  const depositUSDTInContract = async (e) => {
    e.preventDefault();

    const amountInWei = BigNumber.from(amount).mul(BigNumber.from(10).pow(18));

    await props.blockchainData.blockchain.usdtcontract.connected
      .increaseAllowance(
        "0xa0129E9BF73042ec1636BA7Bf0D649Cd03c80a4F",
        amountInWei
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));

    await props.blockchainData.blockchain.maincontract.connected
      .deposit(amountInWei)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    console.log(amount);
  }, [amount]);

  return (
    <div className=" register-main mt-5 pt-5 ">
      <Container className="container ">
        <Row className="row ">
          <div className="spacer-double "></div>
          <Col className="col-12 text-center ">
            <h4>Welcome To Victoria</h4>
          </Col>

          <Col
            className="col-12  p-3 "
            style={{
              justifyContent: "center",
            }}
          >
            <h6
              style={{
                textAlign: "justify",
                wordSpacing: "-2px",
                textJustify: "left",
              }}
            >
              Victoria uses its strong private venture capital background to
              fund mining groups and is also committed to promoting the
              development of blockchain in the financial sector. We have
              introduced staking products with high annualized yields to provide
              community members with the opportunity to obtain more wealth and
              share in the golden shareholder equity. These measures will help
              expand our community, drive innovation in the financial and gold
              industries, and create more opportunities and value for the
              future. Join us and share a successful future together!{" "}
            </h6>
          </Col>

          <Col
            className="  col-12 text-center m-auto p-4"
            style={{
              border: "0.2px solid white",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                justifyContent: "center",
              }}
            >
              <h2> Deposit</h2>
              <h6>
                You need to deposit USDT to stake get $GB and enter the amount
                you want to stake.
              </h6>

              <div className="spacer-10"></div>

              <form
                name="contactForm"
                id="contact_form"
                className="form-border justify-content-center"
                onSubmit={depositUSDTInContract}
                action="#"
              >
                <div className="row " style={{ marginTop: "4rem" }}>
                  <div className="col-md-12">
                    <div>
                      <h6 className="text-start">Balance: 0 USDT</h6>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="field-set">
                      <input
                        type="text"
                        name="name"
                        placeholder="0 USDT"
                        id="name"
                        value={amount}
                        onChange={(e) => setamount(e.target.value)}
                        className="form-control text-center bg-gray"
                      />
                    </div>
                  </div>
                </div>
                <div className="row  ">
                  <div className="col-md-12 text-center ">
                    <div className="">
                      <input
                        type="submit"
                        id="send_message"
                        value="Confirm Deposit"
                        className="btn btn-main color-2"
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="spacer-double "></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  blockchainData: state,
});

export default connect(mapStateToProps)(DepositAll);
