import React, { Component } from "react";
import Clock from "../components/Clock";
import Footer from "../components/footer";

export default class Createpage extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.state = {
      files: [],
    };
  }

  onChange(e) {
    var files = e.target.files;
    console.log(files);
    var filesArr = Array.prototype.slice.call(files);
    console.log(filesArr);
    document.getElementById("file_name").style.display = "none";
    this.setState({ files: [...this.state.files, ...filesArr] });
  }

  render() {
    return (
      <div>
        <section className="jumbotron breadcumb no-bg">
          <div className="mainbreadcumb">
            <div className="container">
              <div className="row m-10-hor">
                <div className="col-12">
                  <h1 className="text-center">Create</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row">
            <div className="col-lg-7 offset-lg-1 mb-5">
              <form id="form-create-item" className="form-border" action="#">
                <div className="field-set">
                  <h5>Upload file</h5>

                  <div className="d-create-file">
                    <p id="file_name">PNG, JPG, GIF, WEBP or MP4. Max 200mb.</p>
                    {this.state.files.map((x) => (
                      <p key="{index}">
                        PNG, JPG, GIF, WEBP or MP4. Max 200mb.{x.name}
                      </p>
                    ))}
                    <div className="browse">
                      <input
                        type="button"
                        id="get_file"
                        className="btn-main"
                        value="Browse"
                      />
                      <input
                        id="upload_file"
                        type="file"
                        multiple
                        onChange={this.onChange}
                      />
                    </div>
                  </div>

                  <div className="spacer-single"></div>

                  <h5>Title</h5>
                  <input
                    type="text"
                    name="item_title"
                    id="item_title"
                    className="form-control"
                    placeholder="e.g. 'Crypto Funk"
                  />

                  <div className="spacer-10"></div>

                  <h5>Description</h5>
                  <textarea
                    data-autoresize
                    name="item_desc"
                    id="item_desc"
                    className="form-control"
                    placeholder="e.g. 'This is very limited item'"
                  ></textarea>

                  <div className="spacer-10"></div>

                  <h5>Price</h5>
                  <input
                    type="text"
                    name="item_price"
                    id="item_price"
                    className="form-control"
                    placeholder="enter price for one item (ETH)"
                  />

                  <div className="spacer-10"></div>

                  <h5>Royalties</h5>
                  <input
                    type="text"
                    name="item_royalties"
                    id="item_royalties"
                    className="form-control"
                    placeholder="suggested: 0, 10%, 20%, 30%. Maximum is 70%"
                  />

                  <div className="spacer-10"></div>

                  <input
                    type="button"
                    id="submit"
                    className="btn-main"
                    value="Create Item"
                  />
                </div>
              </form>
            </div>

            <div className="col-lg-3 col-sm-6 col-xs-12">
              <h5>Preview item</h5>
              <div className="nft__item m-0">
                <div className="nft__item_wrap">
                  <span>
                    <img
                      src="./img/collections/coll-item-3.jpg"
                      id="get_file_2"
                      className="lazy nft__item_preview img-fluid"
                      alt=""
                    />
                  </span>
                </div>
                <div className="box-mim text-center pt-3 pb-2 mt-2">
                  <span>
                    <h4>Pinky Ocean</h4>
                  </span>
                  <div className="nft__item_price">
                    0.08 ETH<span>1/20</span>
                  </div>
                </div>
                <div className="  d-flex justify-content-between mt-2">
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      className="fa fa-credit-card"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">SOLD</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">15</div>
                    </div>
                  </div>
                  <div className="col-6 box-mim-down text-center pt-1 pb-2">
                    <i
                      class="fa fa-clock-o"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    <span className="sold-text">TIME</span>
                    <div className="d-flex justify-content-center">
                      <div className="mini-box pt-1 pb-1">12D: 13H</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex  justify-content-between mt-2">
                  <div className="minus-box">
                    <i
                      class="fa fa-minus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                  <div className="target-box text-center">
                    <i
                      class="fa fa-ticket"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>{" "}
                    &nbsp; Tickets
                  </div>
                  <div className="minus-box">
                    <i
                      class="fa fa-plus"
                      aria-hidden="true"
                      style={{ color: "#fff" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}
