import { ADD_ADDRESS, ADD_CB_CONTRACT, ADD_GB_CONTRACT, ADD_MAIN_CONTRACT, ADD_RP_CONTRACT,ADD_GM_CONTRACT, ADD_USDT_CONTRACT, CONNECT_CB_CONTRACT, CONNECT_GB_CONTRACT, CONNECT_MAIN_CONTRACT, CONNECT_RP_CONTRACT,CONNECT_GM_CONTRACT, CONNECT_USDT_CONTRACT} from "../constants/ActionTypes"

const initialState = {
  account: {
    address: ""
  },
  usdtcontract: {
    notconnected: undefined,
    connected: undefined
  },
  gbcontract: {
    notconnected: undefined,
    connected: undefined
  },
  cbcontract: {
    notconnected: undefined,
    connected: undefined
  },
  gmcontract: {
    notconnected: undefined,
    connected: undefined
  },
  rpcontract: {
    notconnected: undefined,
    connected: undefined
  },
  maincontract: {
    notconnected: undefined,
    connected: undefined
  }
}

const blockchainReducer = (state = initialState, action) => {
  switch(action.type){
    case ADD_ADDRESS:
      return {...state, account: {address: action.payload}}
      break
    case ADD_USDT_CONTRACT:
      return {...state, usdtcontract: {notconnected: action.payload,...state.notconnected}}
      break
    case CONNECT_USDT_CONTRACT:
      return {...state, usdtcontract: {...state.notconnected,connected: action.payload}}
      break
    case ADD_GB_CONTRACT:
      return {...state, gbcontract: {notconnected: action.payload, ...state.connected}}
      break
    case CONNECT_GB_CONTRACT: 
      return {...state, gbcontract: {...state.notconnected, connected: action.payload}}
      break
    case ADD_CB_CONTRACT:
      return {...state, cbcntract: {notconnected: action.payload, ...state.connected}}
      break
    case CONNECT_CB_CONTRACT:
      return {...state, cbcontract: {...state.notconnected, connected: action.payload}}
      break
      case ADD_GM_CONTRACT:
        return {...state, gmcontract: {notconnected: action.payload, ...state.connected}}
        break
      case CONNECT_GM_CONTRACT: 
        return {...state, gmcontract: {...state.notconnected, connected: action.payload}}
        break
    case ADD_RP_CONTRACT:
      return {...state, rpcontract: {notconnected: action.payload, ...state.connected}}
      break
    case CONNECT_RP_CONTRACT:
      return {...state, rpcontract: {...state.notconnected, connected: action.payload}}
      break
    case ADD_MAIN_CONTRACT:
      return {...state, maincontract: {notconnected: action.payload, ...state.connected}}
      break
    case CONNECT_MAIN_CONTRACT:
      return {...state, maincontract: {...state.notconnected, connected: action.payload}}    

    default:
      return state
  }
}

export default blockchainReducer

