import React from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { Col, Form, InputGroup } from "react-bootstrap";
import FormContainer from "./FormContainer";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #212428;
  }
`;

const deposit = (props) => (
  <div>
    <GlobalStyles />
    <FormContainer>
      <section className="container ">
        <div className="row">
          <div className="spacer-double"></div>
          <div className="col-md-8 offset-md-2 text ">
            {/* <h3>Don't have an account? Register now.</h3> */}
            <h2 className="mb-5">90 Days Locked</h2>
            <div className="row text-center ">
              <Col className="col-12">
                <h2>Deposit</h2>
              </Col>
            </div>

            <form
              name="contactForm"
              id="contact_form"
              className="form-border justify-content-center"
              action="#"
            >
              <div className="row ">
                <div className="col-md-12">
                  <div className="field-set">
                    <div className="justify-content-md-center ">
                      <label className="mb-1 " style={{ fontSize: "12px" }}>
                        Stake Amount
                      </label>
                      <InputGroup>
                        <Form.Control
                          placeholder="$1,000 "
                          style={{
                            display: "flex",
                            marginBottom: "0px",
                          }}
                        />
                      </InputGroup>
                      <label
                        style={{
                          marginTop: "0px",
                          paddingTop: "0px",
                          fontSize: "12px",
                        }}
                      >
                        Available:0 USDT
                      </label>
                    </div>
                    <InputGroup>
                      <Form.Control
                        className="mt-5"
                        placeholder="Daily Reward "
                        style={{
                          display: "flex",
                          border: "none",
                          borderBottom: "0.5px solid gray",
                          borderRadius: "0px",
                        }}
                      />
                    </InputGroup>
                    <InputGroup>
                      <Form.Control
                        className="mt-1"
                        placeholder="Release Date "
                        style={{
                          display: "flex",
                          border: "none",
                          borderBottom: "0.5px solid gray",
                          borderRadius: "0px",
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>

              <div className="row  ">
                <div className="col-md-12 text-center ">
                  <div className="">
                    <input
                      type="submit"
                      id="send_message"
                      value="Stake"
                      className="btn btn-main color-2"
                      style={{
                        fontSize: "20px",
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </FormContainer>
    {/* <Footer /> */}
  </div>
);
export default deposit;
