import React, { useState } from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FormContainer from "./FormContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import ColumnNew from "../components/ColumnNew";
import { FaPlay, FaPlayCircle } from "react-icons/fa";

const gmToken = (props) => {
  const token = {
    GB: 0,
    CB: 0,
    GM: 0,
    RP: 0,
    Rank: 0,
  };

  const dataGetter = () => {
    props.blockchainData.blockchain.gbcontract
      .myBalance()
      .then((response) => {
        token.GB = response * 1e18;
      })
      .catch((error) => console.log(error));

    props.blockchainData.blockchain.cbcontract
      .myBalance()
      .then((response) => {
        token.CB = response * 1e18;
      })
      .catch((error) => console.log(error));

    props.blockchainData.blockchain.gmcontract
      .myBalance()
      .then((response) => {
        token.GM = response * 1e18;
      })
      .catch((error) => console.log(error));

    props.blockchainData.blockchain.rpcontract
      .myBalance()
      .then((response) => {
        token.RP = response * 1e18;
      })
      .catch((error) => console.log(error));

    props.blockchainData.blockchain.maincontract
      .myRanking()
      .then((response) => {
        token.Rank = response;
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="mb-5 pb-5 register-main account-container">
      <FormContainer>
        <section className="container ">
          <div className="row justify-content-center">
            <div className="spacer-double"></div>
            <div className="col-md-12   text-center">
              <Col>
                <Col className="col-12">
                  <h2 className=" "> GM Token</h2>
                </Col>

                <form
                  name="contactForm"
                  id="contact_form"
                  className="form-border justify-content-center"
                  action="#"
                >
                  <div
                    className="row text-center align-item-center justify-content-center"
                    style={{ marginBottom: "2rem", marginTop: "4rem" }}
                  ></div>
                  <div className="row">
                    <Col sm="12" md="12" lg="6" xl="6">
                      <ColumnNew GM="GM" width="100%" />
                    </Col>
                    <Col
                      className="border p-5 text-center "
                      sm="12"
                      md="12"
                      lg="6"
                      xl="6"
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "black",
                      }}
                    >
                      <div
                        className="row "
                        style={{ marginBottom: "0rem", marginTop: "1rem" }}
                      >
                        <Col>
                          <div className="row text-center">
                            <Col className="  text-center">
                              <h4>Swap you $GM get $PR</h4>
                            </Col>
                          </div>

                          <Col className="col-12">
                            <h1></h1>
                          </Col>
                          <div className="mt-4  text-center d-flex justify-content-center align-items-center ">
                            <div
                              className="col-4 me-2 col-sm-4 h-100 mb-2 d-flex justify-content-right align-items-right  border  box-mim-3 "
                              style={{
                                borderRadius: "20px",
                              }}
                            >
                              <Card className="">
                                <Card.Body>
                                  <Card.Title>
                                    <div className="row text-center">
                                      <Col className="  d-flex justify-content-center align-items-center mb-3 w-100">
                                        <Card.Body
                                          className="text-center "
                                          style={{
                                            height: "fit-content",
                                            backgroundColor: "#e58a06",
                                            width: "100%",
                                          }}
                                        >
                                          <span className="text-center">
                                            $GM
                                          </span>
                                        </Card.Body>
                                      </Col>
                                    </div>
                                    <h4 style={{ fontSize: "20px" }}>
                                      {token.GB}
                                    </h4>
                                  </Card.Title>
                                </Card.Body>
                              </Card>
                            </div>

                            <div
                              className="col-4 col-sm-4 mb-2 d-flex justify-content-right align-items-right  border  box-mim-3 "
                              style={{
                                borderRadius: "20px",
                              }}
                            >
                              <Card className="">
                                <Card.Body>
                                  <Card.Title>
                                    <div className="row text-center">
                                      <Col className="  d-flex justify-content-center align-items-center mb-3">
                                        <Card.Body
                                          className="text-center "
                                          style={{
                                            height: "fit-content",
                                            backgroundColor: "#e58a06",
                                          }}
                                        >
                                          <span className="text-center">
                                            $GM
                                          </span>
                                        </Card.Body>
                                      </Col>
                                    </div>
                                    <h4 style={{ fontSize: "20px" }}>
                                      {token.GB}
                                    </h4>
                                  </Card.Title>
                                </Card.Body>
                              </Card>
                            </div>
                          </div>

                          {/* <div className="row mt-2 text-center d-flex justify-content-center align-items-center">
                    <div className="col-6 d-flex justify-content-right align-items-right">
                      <Card className="" style={{ width: "100%" }}>
                        <Card.Body>
                          <Card.Title>
                            <h4 style={{ fontSize: "40px" }}>{token.GM}</h4>
                            <strong>Total GM</strong>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-6 d-flex justify-content-end align-items-center">
                      <Card className="" style={{ width: "100%" }}>
                        <Card.Body>
                          <h4 style={{ fontSize: "40px" }}>{token.RP}</h4>
                          <Card.Title>
                            <strong>Total PR</strong>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  </div> */}
                          <Col className="col-12 mt-2  gw-footer">
                            <h5 className="text-start">Balance: </h5>
                            <br />
                            <h6 className="text-end">
                              1000 $GM = 1 $PR (1$GM Tax)
                            </h6>
                          </Col>
                        </Col>
                        <div className="row  mt-5">
                          <div className="col-md-12 text-center ">
                            <div className="">
                              <input
                                type="submit"
                                id="send_message"
                                value="Swap"
                                className="btn btn-main color-2"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </form>
              </Col>
            </div>
          </div>
        </section>
      </FormContainer>
      {/* <Footer /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { blockchainData: state };
};
export default connect(mapStateToProps)(gmToken);
