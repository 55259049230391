import React, { useState } from "react";
import { Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
const WithdrawAll = (props) => {

  const [amount, setamount] = useState(0)

  const withdrowUSDTFromContract = (e) => {
    e.preventDefault()
    props.blockchainData.blockchain.maincontract.connected.withdraw(amount)
    .then((response) => {
      console.log(response)
    })
    .catch(error => console.log(error))
  }
  return (
    <div
      className=" register-main mt-5 pt-5 "
      style={{
        height: "750px",
      }}
    >
      <Container className="container ">
        <Row className="row ">
          <div className="spacer-double "></div>
          <Col className=" offset-md-1 mt-5">
            <h2> Withdraw</h2>

            <div className="spacer-10"></div>

            <form
              name="contactForm"
              id="contact_form"
              className="form-border justify-content-center"
              onSubmit={withdrowUSDTFromContract}
              action="#"
              style={{
                width: "85%",
              }}
            >
              <div className="row " style={{ marginTop: "4rem" }}>
                <div className="col-md-12">
                  <div className="field-set">
                    <label
                      className="mb-3 ms-1"
                      style={{
                        fontSize: "20px",
                        color: "white",
                      }}
                    >
                      Amount
                    </label>
                    <input
                      type="text"
                      name="name"
                      placeholder="1231234/-"
                      id="name"
                      value={amount}
                      onChange={(e) => setamount(e.target.value)}
                      className="form-control text-center"
                    />
                  </div>
                </div>
              </div>

              <div className="row  ">
                <div className="col-md-12 text-center ">
                  <div className="">
                    <input
                      type="submit"
                      id="send_message"
                      value="Confirm"
                      className="btn btn-main color-2"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  blockchainData: state,
});

export default connect(mapStateToProps) (WithdrawAll);
