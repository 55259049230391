import { ADD_ADDRESS, ADD_CB_CONTRACT, ADD_GB_CONTRACT,ADD_GM_CONTRACT, ADD_MAIN_CONTRACT, ADD_RP_CONTRACT, ADD_USDT_CONTRACT, CONNECT_CB_CONTRACT, CONNECT_GB_CONTRACT, CONNECT_MAIN_CONTRACT,CONNECT_GM_CONTRACT , CONNECT_RP_CONTRACT, CONNECT_USDT_CONTRACT } from "../constants/ActionTypes";

  export const addAddress = (address) => ({type: ADD_ADDRESS,payload: address})
  export const addUSDTContract = (usdtContract) => ({type: ADD_USDT_CONTRACT,payload: usdtContract})
  export const addGBContract = (gbContract) => ({type: ADD_GB_CONTRACT,payload: gbContract})
  export const addCBContract = (cbContract) => ({type: ADD_CB_CONTRACT,payload: cbContract})
  export const addGMContract = (gmContract) => ({type: ADD_GM_CONTRACT,payload: gmContract})
  export const addRPTContract = (rpContract) => ({type: ADD_RP_CONTRACT,payload: rpContract})
  export const addMainContract = (mainContract) => ({type: ADD_MAIN_CONTRACT,payload: mainContract})
  export const connectUSDTContract = (usdtContractConnected) => ({type: CONNECT_USDT_CONTRACT,payload: usdtContractConnected})
  export const connectGBContract = (gbContractConnected) => ({type: CONNECT_GB_CONTRACT,payload: gbContractConnected})
  export const connectCBContract = (cbContractConnected) => ({type: CONNECT_CB_CONTRACT,payload: cbContractConnected})
  export const connectGMContract = (gmContractConnected) => ({type: CONNECT_GM_CONTRACT,payload: gmContractConnected})
  export const connectRPTContract = (rpContractConnected) => ({type: CONNECT_RP_CONTRACT,payload: rpContractConnected})
  export const connectMainContract = (mainContractConnected) => ({type: CONNECT_MAIN_CONTRACT,payload: mainContractConnected})