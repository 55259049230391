import React from "react";
import { Router, Location, Redirect } from "@reach/router";
import ScrollToTopBtn from "./menu/ScrollToTop";
import Footer from "./components/footer";
import Header from "./menu/header";
import Home from "./pages/home";
import Home1 from "./pages/home1";
import Home2 from "./pages/home2";
import Explore from "./pages/explore";
import Explore2 from "./pages/explore2";
import Helpcenter from "./pages/helpcenter";
import Rangking from "./pages/rangking";
import Colection from "./pages/colection";
import ItemDetail from "./pages/ItemDetail";
import Author from "./pages/Author";
import Wallet from "./pages/wallet";
import Login from "./pages/login";
import LoginTwo from "./pages/loginTwo";
import Register from "./pages/register";
import Price from "./pages/price";
import Works from "./pages/works";
import News from "./pages/news";
import Create from "./pages/create";
import Auction from "./pages/Auction";
import Activity from "./pages/activity";
import Contact from "./pages/contact";
import ElegantIcons from "./pages/elegantIcons";
import GMToken from "./pages/GMToken";
import EtlineIcons from "./pages/etlineIcons";
import FontAwesomeIcons from "./pages/fontAwesomeIcons";
import Accordion from "./pages/accordion";
import Alerts from "./pages/alerts";
import Progressbar from "./pages/progressbar";
import Tabs from "./pages/tabs";
import DepositForm from "./pages/days-deposit";
import FlexibleDeposit from "./pages/flexible-deposit ";
import TaskList from "./pages/Task-List";
import Account from "./pages/Account";
import Exchange from "./pages/Exchange";
import { createGlobalStyle } from "styled-components";
import DepositAll from "./pages/DepositAll";
import WithdrawAll from "./pages/WithdrawAll";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};

const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <div id="routerhang">
        <div key={location.key}>
          <Router location={location}>{children}</Router>
        </div>
      </div>
    )}
  </Location>
);

const app = () => (
  <div className="wraper">
    <GlobalStyles />
    <Header />
    <PosedRouter>
      <ScrollTop path="/">
        <Home exact path="/" name="home">
          <Redirect to="/home" />
        </Home>
        <Home1 path="/home1" />
        <GMToken path="/token"/>
        <Home2 path="/home2" />
        <Explore path="/explore" />
        <Explore2 path="/explore2" />
        <Helpcenter path="/helpcenter" />
        <Rangking path="/rangking" />
        <Colection path="/colection" />
        <ItemDetail path="/ItemDetail" />
        <DepositForm path="/days-deposit" />
        <DepositAll path="/deposit-all" />
        <WithdrawAll path="/withdraw-all" />
        <Account path="/account" />
        <FlexibleDeposit path="/flexible-deposit" />
        <TaskList path="/task-list" />
        <Exchange path="/exchange" />
        <Author path="/Author" />
        <Wallet path="/wallet" />
        <Login path="/login" />
        <LoginTwo path="/loginTwo" />
        <Register path="/register" />
        <Price path="/price" />
        <Works path="/works" />
        <News path="/news" />
        <Create path="/create" />
        <Auction path="/Auction" />
        <Activity path="/activity" />
        <Contact path="/contact" />
        <ElegantIcons path="/elegantIcons" />
        <EtlineIcons path="/etlineIcons" />
        <FontAwesomeIcons path="/fontAwesomeIcons" />
        <Accordion path="/accordion" />
        <Alerts path="/alerts" />
        <Progressbar path="/progressbar" />
        <Tabs path="/tabs" />
      </ScrollTop>
    </PosedRouter>
    <ScrollToTopBtn />
    <Footer />
  </div>
);

export default app;
