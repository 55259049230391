import React from 'react'
import { AiFillHeart } from "react-icons/ai"
import { LuMoreHorizontal } from "react-icons/lu"
import { RxAvatar } from "react-icons/rx"
import { FaAdn } from "react-icons/fa"
import Topseller from './topseller'
function Home3() {
    return (
        <div className='container-fluid'>
            <div className='row d-flex justify-content-center mt-4'>
                <div className='col-12 text-center mt-4'>
                    <span className='buy-sell '>BUY, SELL, & DISCOVER RARE DIGITAL ITEMS</span>
                    <h2 className='mt-3'>Victoria</h2>
                </div>

                <div className='col-md-12 col-11 bg-black mt-3'>
                    <div className='container'>
                        <div className='row d-flex justify-content-evenly mt-3 mb-3'>
                            <div className='col-md-4 col-12 text-center col-width  ' style={{ borderRight: "3px solid rgb(61, 61, 61)" }}>
                                <img src="./img/author/author-1.jpg" className='image-width m-2' />
                                <img src="./img/author/author-10.jpg" className='image-width m-2' />
                                <img src="./img/author/author-11.jpg" className='image-width m-2' />
                                <img src="./img/author/author-12.jpg" className='image-width m-2' />
                                <img src="./img/author/author-1.jpg" className='image-width m-2' />
                                <img src="./img/author/author-10.jpg" className='image-width m-2' />
                                <img src="./img/author/author-11.jpg" className='image-width m-2' />
                                <img src="./img/author/author-12.jpg" className='image-width m-2' />
                                <img src="./img/author/author-1.jpg" className='image-width m-2' />
                                <img src="./img/author/author-10.jpg" className='image-width m-2' />
                                <img src="./img/author/author-11.jpg" className='image-width m-2' />
                                <img src="./img/author/author-12.jpg" className='image-width m-2' />

                            </div>
                            <div className='col-md-4 col-12 col-width  text-center'>
                                <img src="./img/collections/coll-item-4.jpg" className='iamge1-width' />
                            </div>
                            <div className='col-md-4 col-12 col-width '>
                                <div className='d-flex justify-content-between align-items-center mt-2'>
                                    <div>
                                        <span className='UNIVERSAL-span'>UNIVERSAL TRAVEL</span>
                                        <br />
                                        <span className="eth-span"><span className='text-white'>0.34 ETH &nbsp;</span> $538.12 1 of 1</span>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='heart-box me-2'>
                                            <AiFillHeart color='#000' size={20} />
                                        </div>
                                        <div className='more-box'>
                                            <LuMoreHorizontal color='#fff' size={20} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <span className="eth-span">Artist: <span className='text-white'>Leo Takaso</span></span><br />
                                    <span className="eth-span">Size: <span className='text-white'>3000 x 3000</span></span><br />
                                    <span className="eth-span">Created: <span className='text-white'>03 April, 2023</span></span><br />
                                </div>
                                <div className='d-flex align-items-center mt-4'>
                                    <RxAvatar size={30} color="#fff" />
                                    <div className='d-flex flex-columns ms-2'>
                                        <span className="eth-span">Owner<br /> <span className='text-white'>Victoria</span></span>
                                    </div>
                                </div>

                                <div className='highest-box mt-4'>
                                    <span className='buy-sell '>Highest bid:</span>

                                    <div className='d-flex justify-content-between align-items-center mt-2 mb-2'>
                                        <div>
                                            <FaAdn size={25} color="orange" />
                                            <span className='text-white ms-1'>Victoria</span>
                                        </div>
                                        <div>
                                            <span className="eth-span"><span className='text-white'>0.34 ETH &nbsp;</span> $538.12</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between align-items-center mt-5'>
                                    <button className=' btn-buy' size="sm">Buy now</button>
                                    <button className=' btn-bid' size="sm">Bid</button>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className='col-12 mt-5'>
                    <div className='container'>
                        <h3>TOP sellers this month</h3>
                        <Topseller/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home3