import React, { useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";

const Register = (props) => {
  const [address, setaddress] = useState("");

  const addReferralAddress = (e) => {
    e.preventDefault();

    if (address == "") {
      setaddress("0x970dF9491565e00172996dAEc8aF51a3BD5cdabF");
    }

    props.blockchainData.blockchain.maincontract.connected
      .registerWithReferral(address)
      .then((resopnse) => console.log(resopnse))
      .catch((error) => console.log(error));
  };
  return (
    <div
      className=" ms-5 register-main mt-5 pt-5 "
      style={{
        height: "750px",
      }}
    >
      <Container className="container ">
        <Row className="row ">
          <div className="spacer-double "></div>
          <Col className=" offset-md-1  mt-5">
            <Col className="col-10 text-center">
              <h2 className=" mb-4"> Register</h2>
              <h6
                style={{
                  fontWeight: "400",
                }}
              >
                You need to enter your inviter's wallet address before staking
              </h6>
            </Col>

            <form
              name="contactForm"
              id="contact_form"
              className="form-border justify-content-center"
              action="#"
              onSubmit={addReferralAddress}
              style={{
                width: "85%",
              }}
            >
              <div
                className="row "
                style={{ marginBottom: "2rem", marginTop: "4rem" }}
              >
                <div className="col-md-12">
                  <div className="field-set">
                    <h6 className="mb-3">Inviter' ID:</h6>
                    <input
                      type="text"
                      name="name"
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                      id="name"
                      className="form-control text-center "
                      style={{
                        borderRadius: "0px",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row  ">
                <div className="col-md-12 text-center ">
                  <div className="">
                    <input
                      type="submit"
                      id="send_message"
                      value="Sign In"
                      className="btn btn-main color-2"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  blockchainData: state,
});

export default connect(mapStateToProps)(Register);
